import {
  App,
  DeriveTableProps,
  Plugin,
  Table,
  TableDefinition,
  View,
} from "@pimo/pimo-app-builder";
import {
  ABBREVIATIONS,
  APP_ROUTES,
  DASHBOARD_PAGE_SECTIONS,
} from "../constants";
import {
  ComponentTitleCard,
  CompoundLayout,
  CompoundLayoutProps,
  DonutChart,
  GroupedBarChart,
  HorizontalBarChart,
  ImageCard,
  SectionHeader,
  SeparateHorizontalLegend,
  SimpleTextCardWithTitle,
  StackedBarChart,
  TableWithChipHeaders,
  TextCardCell,
  TitleCard,
} from "@pimo/pimo-components";
import { HealthIndicatorCard } from "../components/health-indicator-card";
import dayjs from "dayjs";
import { theme } from "../theme";
import {
  RISK_CURRENT_PAST_COLOR,
  RISK_TYPE_DETAILS,
  RISK_SEVERITIES,
  formatListWithBullets,
  getPercentage,
  findCategoryWithHighestSum,
  isOlderThanTwoYears,
  getCurrentQuarterYear,
  getNextQuarterYear,
  isBeyondOneYear,
} from "rr-utils";
import { fetchDashboard } from "../helpers/fetch-dashboard";
import { RrAppState } from "../app";
import {
  IncorrectOwnership,
  OeProject,
  RiskScore,
  ScoreForAdditionalAttentionPoints,
  ScoreForThreeOrMoreAssesments,
} from "rr-types";
import { fetchOverlay } from "../helpers/fetch-overlay";
import { OverviewTable } from "../components/overview-table";
import { IndentedTextCardWithTitle } from "../components/indented-text-card";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DashboardPluginState {}
export class DashboardPlugin implements Plugin<RrAppState> {
  private view?: View<RrAppState, CompoundLayoutProps>;
  onRegister(app: App<RrAppState>): void {
    this.view = app.createView({
      name: "Dashboard",
      layout: new CompoundLayout({
        areas: {
          title: { id: "title" },

          sections: [
            {
              header: { id: "introductionHeader" },
            },
            {
              components: [
                { id: "objectiveAndScopeTitle" },
                { id: "objectiveCard" },
                { id: "scopeCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"objectiveAndScopeTitle" "objectiveCard" "scopeCard"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "oesTableTitle" }, { id: "regionsOesTable" }],
              style: {
                sx: {
                  gridTemplateAreas: `"oesTableTitle" "regionsOesTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              components: [
                { id: "functionalMonitoringTitle" },
                { id: "functionalMonitoringImage" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"functionalMonitoringTitle" "functionalMonitoringImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              components: [{ id: "kriTitle" }, { id: "kriImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"kriTitle" "kriImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "iirmTitle" }, { id: "iirmImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"iirmTitle" "iirmImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [{ id: "threeStepTitle" }, { id: "threeStepImage" }],
              style: {
                sx: {
                  gridTemplateAreas: `"threeStepTitle" "threeStepImage"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ISRiskReportSummaryHeader" },
            },

            {
              header: { id: "healthIndicatorCard" },
            },
            {
              components: [
                { id: "groupOverviewTitle" },
                { id: "groupKeyMessage" },
                { id: "regionTable1" },
                { id: "regionTable2" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"groupOverviewTitle groupOverviewTitle" "groupKeyMessage groupKeyMessage" "regionTable1 regionTable2"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "aggregateHeader" },
            },
            {
              components: [
                { id: "allISRiskCardTitle" },
                { id: "allOaRCard" },
                { id: "ISMSOaRCard" },
                { id: "BAOaRCard" },
                { id: "ITOMOaRCard" },
                { id: "IT-ServiceOaRCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"allISRiskCardTitle allISRiskCardTitle" "allOaRCard allOaRCard" "ISMSOaRCard BAOaRCard" "ITOMOaRCard IT-ServiceOaRCard"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ownershipHeader" },
            },
            {
              components: [
                { id: "ownershipKeyMessage" },
                { id: "ownershipCardTitle" },
                { id: "ismsOarChart" },
                { id: "baOarChart" },
                { id: "itomOarChart" },
                { id: "serviceOarChart" },
                { id: "legendOwnershipRisks" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "ownershipCardTitle ownershipCardTitle ownershipCardTitle" "ownershipKeyMessage ismsOarChart baOarChart" "ownershipKeyMessage itomOarChart serviceOarChart" "legendOwnershipRisks legendOwnershipRisks legendOwnershipRisks"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "timelyRiskManagementHeader" },
            },
            {
              components: [
                { id: "timelyRiskCardTitle" },
                { id: "timelyRiskOaRChart" },
                { id: "timelyRiskismsOaRChart" },
                { id: "timelyRiskBaOaRChart" },
                { id: "timelyRiskItomOaRChart" },
                { id: "timelyRiskServiceOaRChart" },
                { id: "timelyRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "timelyRiskCardTitle timelyRiskCardTitle timelyRiskCardTitle" "timelyRiskOaRChart timelyRiskismsOaRChart timelyRiskBaOaRChart" "timelyRiskOaRChart timelyRiskItomOaRChart timelyRiskServiceOaRChart" "timelyRiskLegend timelyRiskLegend timelyRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "timelyOverdueRiskCardTitle" },
                { id: "allISOverdueRiskChart" },
                { id: "overdueRiskismsOaRChart" },
                { id: "overdueRiskBaOaRChart" },
                { id: "overdueRiskItomOaRChart" },
                { id: "overdueRiskServiceOaRChart" },
                { id: "overdueRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "timelyOverdueRiskCardTitle timelyOverdueRiskCardTitle timelyOverdueRiskCardTitle" "allISOverdueRiskChart overdueRiskismsOaRChart overdueRiskBaOaRChart" "allISOverdueRiskChart overdueRiskItomOaRChart overdueRiskServiceOaRChart" "overdueRiskLegend overdueRiskLegend overdueRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "acceptedRiskManagementHeader" },
            },
            {
              components: [
                { id: "acceptedRiskCardTitle" },
                { id: "acceptedAllISRiskChart" },
                { id: "acceptedRiskIsmsOaRChart" },
                { id: "acceptedBaOaRChart" },
                { id: "acceptedRiskItomOaRChart" },
                { id: "acceptedRiskServiceOaRChart" },
                { id: "acceptedRiskLegend" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "acceptedRiskCardTitle acceptedRiskCardTitle acceptedRiskCardTitle" "acceptedAllISRiskChart acceptedRiskIsmsOaRChart acceptedBaOaRChart" "acceptedAllISRiskChart acceptedRiskItomOaRChart acceptedRiskServiceOaRChart" "acceptedRiskLegend acceptedRiskLegend acceptedRiskLegend"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "ineffectiveControlsHeader" },
            },
            {
              components: [
                { id: "ineffectiveControlsCardTitle" },
                { id: "overviewRisksCard" },
                { id: "overviewNumberOfRisksCard" },
                { id: "firstRiskCard" },
                { id: "secondRiskCard" },
                { id: "ineffectiveControlsRisks" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "overviewRisksCard overviewRisksCard" "ineffectiveControlsCardTitle ineffectiveControlsCardTitle"  "overviewNumberOfRisksCard overviewNumberOfRisksCard" "firstRiskCard secondRiskCard" "ineffectiveControlsRisks ineffectiveControlsRisks"`,
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "additionalPointsHeader" },
            },
            {
              components: [
                { id: "risksAssessmentCyclesCardTitle" },
                { id: "allIsRisksReassesmentCard" },
                { id: "ismsOaRCardReassessment" },
                { id: "baOaRCardReassessment" },
                { id: "itomOaRCardReassessment" },
                { id: "serviceOaRCardReassessment" },
                { id: "legendCardReassessment" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksAssessmentCyclesCardTitle risksAssessmentCyclesCardTitle risksAssessmentCyclesCardTitle" "allIsRisksReassesmentCard ismsOaRCardReassessment baOaRCardReassessment" "allIsRisksReassesmentCard itomOaRCardReassessment serviceOaRCardReassessment" "legendCardReassessment legendCardReassessment legendCardReassessment"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksFirstPublishedCardTitle" },
                { id: "allIsRisksFirstPublishedCard" },
                { id: "ismsOaRCardFirstPublished" },
                { id: "baOaRCardFirstPublished" },
                { id: "itomOaRCardFirstPublished" },
                { id: "serviceOaRCardFirstPublished" },
                { id: "legendCardFirstPublished" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksFirstPublishedCardTitle risksFirstPublishedCardTitle risksFirstPublishedCardTitle" "allIsRisksFirstPublishedCard ismsOaRCardFirstPublished baOaRCardFirstPublished" "allIsRisksFirstPublishedCard itomOaRCardFirstPublished serviceOaRCardFirstPublished" "legendCardFirstPublished legendCardFirstPublished legendCardFirstPublished"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksEstimatedCompletionCardTitle" },
                { id: "allIsRisksEstimatedCompletionCard" },
                { id: "ismsOaRCardEstimatedCompletion" },
                { id: "baOaRCardEstimatedCompletion" },
                { id: "itomOaRCardEstimatedCompletion" },
                { id: "serviceOaRCardEstimatedCompletion" },
                { id: "legendCardEstimatedCompletion" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksEstimatedCompletionCardTitle risksEstimatedCompletionCardTitle risksEstimatedCompletionCardTitle" "allIsRisksEstimatedCompletionCard ismsOaRCardEstimatedCompletion baOaRCardEstimatedCompletion" "allIsRisksEstimatedCompletionCard itomOaRCardEstimatedCompletion serviceOaRCardEstimatedCompletion" "legendCardEstimatedCompletion legendCardEstimatedCompletion legendCardEstimatedCompletion"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "risksAcceptedResponseCardTitle" },
                { id: "allIsRisksAcceptedResponseCard" },
                { id: "ismsOaRCardAcceptedResponse" },
                { id: "baOaRCardAcceptedResponse" },
                { id: "itomOaRCardAcceptedResponse" },
                { id: "serviceOaRCardAcceptedResponse" },
                { id: "legendCardAcceptedResponse" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "risksAcceptedResponseCardTitle risksAcceptedResponseCardTitle risksAcceptedResponseCardTitle" "allIsRisksAcceptedResponseCard ismsOaRCardAcceptedResponse baOaRCardAcceptedResponse" "allIsRisksAcceptedResponseCard itomOaRCardAcceptedResponse serviceOaRCardAcceptedResponse" "legendCardAcceptedResponse legendCardAcceptedResponse legendCardAcceptedResponse"`,
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "appendixHeader" },
            },
            {
              components: [
                { id: "appendixApprovedQuestionnairesDetails" },
                { id: "appendixApprovedQuestionnairesSubheader" },
                { id: "appendixApprovedQuestionnairesTable" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "appendixApprovedQuestionnairesDetails" "appendixApprovedQuestionnairesSubheader" "appendixApprovedQuestionnairesTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              components: [
                { id: "appendixRiskTitle" },
                { id: "appendixKeyMessage" },
                { id: "appendixRiskTable" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "appendixRiskTitle" "appendixKeyMessage" "appendixRiskTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },

            {
              components: [
                { id: "appendixDelayedRiskTitle" },
                { id: "delayedRiskTableCard" },
                { id: "appendixDelayedRiskFooterCard" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: ` "appendixDelayedRiskTitle" "delayedRiskTableCard" "appendixDelayedRiskFooterCard"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
            {
              header: { id: "abbreviationsHeader" },
            },
            {
              components: [
                { id: "abbreviationsTitle" },
                { id: "abbreviationsTable" },
              ],
              style: {
                sx: {
                  gridTemplateAreas: `"abbreviationsTitle" "abbreviationsTable"`,
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gridTemplateRows: "auto",
                  overflow: "auto",
                },
              },
            },
          ],
        },
        loadingScreenTimeout: 200,
      }),
    });

    const headline = this.view.addComponent({
      component: TitleCard,
      layoutProps: { id: "title", xs: 12 },
    });

    const defaultDate = dayjs()
      .endOf("month")
      .subtract(1, "month")
      .format("DD/MM/YYYY");

    headline.mapState(({ dashboard }) => {
      return {
        title: `IS Risk Report Group Report as of ${dashboard?.timestamp ? dayjs(dashboard?.timestamp).format("DD/MM/YYYY") : defaultDate}`,
      };
    });
    const view = this.view;
    this.buildDashboardPage(view);
    const route = app.createRoute({
      path: APP_ROUTES.dashboard,
      view,
    });
    route.on("load", async () => {
      const [{ dashboardData, oes }, data] = await Promise.all([
        fetchDashboard(),
        fetchOverlay(),
      ]);

      if (!dashboardData) {
        app.navigate(APP_ROUTES.noData);
      }
      app.setAppState({
        ...app.getAppState(),
        dashboard: dashboardData,
        oes,
        oesForMenu: data?.oesForMenu,
        regionsForMenu: data?.regionsForMenu,
      });
    });
  }

  buildDashboardPage(view: View<RrAppState, CompoundLayoutProps>) {
    if (!view) {
      return;
    }

    this.buildIntroductionSection(view);
    this.buildHealthIndicatorSection(view);
    this.buildAggregatedViewSection(view);
    this.buildOwnershipSection(view);
    this.buildTimelyRiskManagementSection(view);
    this.buildAcceptedRiskSection(view);
    this.buildIneffectiveControlsSection(view);
    this.buildAdditionalAttentionPoints(view);
    this.buildAppendixSection(view);
    this.buildAbbrevationsSection(view);
  }

  private buildIntroductionSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const introductionHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "introductionHeader",
      },
    });

    introductionHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[0],
      id: DASHBOARD_PAGE_SECTIONS[0],
      sx: { scrollMarginTop: "80px" },
    }));

    const objectiveAndScopeTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "objectiveAndScopeTitle",
        xs: 12,
      },
    });
    objectiveAndScopeTitle.mapState(() => ({
      title: "Objective and Scope of the Report",
    }));
    const objectiveCard = view.addComponent({
      component: SimpleTextCardWithTitle,
      layoutProps: {
        id: "objectiveCard",
      },
    });

    objectiveCard.mapState(() => ({
      cardProps: { sx: { p: 0 } },
      title: "Objective",
      text: "This report acts as an in-depth supplement, offering specific data-driven actions to enhance the maturity of Information Security Risk Response when following the IIRM process.",
    }));

    const scopeCard = view.addComponent({
      component: SimpleTextCardWithTitle,
      layoutProps: {
        id: "scopeCard",
      },
    });

    scopeCard.mapState(() => ({
      cardProps: { sx: { p: 0 } },
      title: "Scope",
      text: formatListWithBullets([
        "Risks resulting from IS control deficiency (IS risks) in Archer",
        "First identified more than 30 calendar days prior to the date of the report ",
        "Objects at risk: ISMS, BA, ITOM and IT-Service",
      ]),
    }));

    const oesTableTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "oesTableTitle",
      },
    });

    oesTableTitle.mapState((state) => ({
      title: `Integrated OEs: ${state.oesForMenu.length}`,
    }));

    const functionalMonitoringTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "functionalMonitoringTitle",
        xs: 12,
      },
    });
    functionalMonitoringTitle.mapState(() => ({
      title:
        "Functional Monitoring of Group Information Security requirements is done regularly",
    }));

    const functionalMonitoringImage = view.addComponent({
      component: ImageCard,
      layoutProps: {
        id: "functionalMonitoringImage",
        xs: 12,
      },
    });
    functionalMonitoringImage.mapState(() => ({
      src: "apitis.png",
      cardProps: { sx: { maxHeight: 350 } },
    }));

    const kriTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "kriTitle",
        xs: 12,
      },
    });
    kriTitle.mapState(() => ({
      title:
        "OE Information Security status based on standardized KRI (Key Risk Indicator)",
    }));

    const kriImage = view.addComponent({
      component: ImageCard,
      layoutProps: {
        id: "kriImage",
        xs: 12,
      },
    });
    kriImage.mapState(() => ({
      cardProps: { sx: { maxHeight: 350 } },
      src: "kri.png",
    }));

    const iirmTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "iirmTitle",
        xs: 12,
      },
    });
    iirmTitle.mapState(() => ({
      title:
        "The Integrated Information Risk Management (IIRM) Process ensures a proper risk lifecycle",
    }));

    const iirmImage = view.addComponent({
      component: ImageCard,
      layoutProps: {
        id: "iirmImage",
        xs: 12,
      },
    });
    iirmImage.mapState(() => ({
      cardProps: { sx: { maxHeight: 350 } },
      src: "iirm.png",
    }));

    const threeStepTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "threeStepTitle",
        xs: 12,
      },
    });
    threeStepTitle.mapState(() => ({
      title:
        "Information Risks are regularly assessed in a 3-step process based on AFIRM​",
    }));

    const threeStepImage = view.addComponent({
      component: ImageCard,
      layoutProps: {
        id: "threeStepImage",
        xs: 12,
      },
    });
    threeStepImage.mapState(() => ({
      cardProps: { sx: { maxHeight: 350 } },
      src: "3step.png",
    }));
  }

  private buildHealthIndicatorSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const isRiskReportSummaryHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "ISRiskReportSummaryHeader",
      },
    });

    isRiskReportSummaryHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[1],
      id: DASHBOARD_PAGE_SECTIONS[1],
      sx: { scrollMarginTop: "80px" },
    }));

    const healthIndicatorCard = view.addComponent({
      component: HealthIndicatorCard,
      layoutProps: {
        xs: 12,
        id: `healthIndicatorCard`,
      },
    });

    healthIndicatorCard.mapState((state) => {
      const responseDecision = state.dashboard?.responseDecision;

      const totalIncorrectOwnership =
        (state.dashboard?.currentQuarterOwnership?.ba ?? 0) +
        (state.dashboard?.currentQuarterOwnership?.isms ?? 0) +
        (state.dashboard?.currentQuarterOwnership?.itom ?? 0) +
        (state.dashboard?.currentQuarterOwnership?.itService ?? 0);

      const totalNumberOfRisks = state.dashboard?.totalNumberOfRisks ?? 0;
      const totalDelayedRisks =
        (state.dashboard?.currentQuarterDelayed?.overall?.high ?? 0) +
        (state.dashboard?.currentQuarterDelayed?.overall?.moderate ?? 0) +
        (state.dashboard?.currentQuarterDelayed?.overall?.low ?? 0) +
        (state.dashboard?.currentQuarterDelayed?.overall?.veryLow ?? 0) +
        (state.dashboard?.currentQuarterDelayed?.overall?.veryHigh ?? 0) +
        (state.dashboard?.currentQuarterDelayed?.overall?.noSelection ?? 0);

      const totalOverdueRisks =
        (state.dashboard?.overallOverdueMitigation?.veryHigh
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
        (state.dashboard?.overallOverdueMitigation?.high
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
        (state.dashboard?.overallOverdueMitigation?.moderate
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
        (state.dashboard?.overallOverdueMitigation?.low
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
        (state.dashboard?.overallOverdueMitigation?.veryLow
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0) +
        (state.dashboard?.overallOverdueMitigation?.noSelection
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) ?? 0);

      return {
        title: `Aggregated Overview of ISRR Health Indicator `,
        riskAssessmentProps: {
          progressBarDetails: {
            label: `ISMS Questionnaire approved ${state.dashboard?.numberOfApprovals ?? 0}/${state.oesForMenu.length}`,
            value: getPercentage(
              state.oesForMenu.length,
              state.dashboard?.numberOfApprovals ?? 0
            ),
          },
          headers: [
            {
              title: "Inherent Risk Assesment",
              subtitle:
                state.dashboard?.status === "Approved"
                  ? "ISMS Questionnaire has been approved."
                  : "ISMS Questionnaire has not been approved.",
            },
          ],
          cardProps: { sx: { background: "#f5f5f5" } },
        },
        riskOwnershipProps: {
          donutChartDetails: [
            {
              labelsOffset: 22,
              enableDataLabels: false,
              enableLegend: false,
              series: [
                totalIncorrectOwnership,
                totalNumberOfRisks - totalIncorrectOwnership,
              ],
              backgroundColor: [
                "#94CADC",
                theme.palette.primary.main,
                theme.palette.secondary.main,
              ],

              labels: ["Incorrect Ownership", "Correct Ownership"],
              background: "#f5f5f5",
              showLabels: true,

              toolTipEnabled: true,
              toolTipLabels: ["Incorrect Ownership", "Correct Ownership"],
            },
          ],
          footerSectionText:
            "Assign risks to the correct owner according to AFIRM.",
          footerSectionTitle: "Required Actions",
          cardProps: { sx: { width: "50%", background: "#f5f5f5" } },
          headers: [
            {
              title: "Risk ownership",
              chipText: "Weighted Score: 30%",
              subtitle: `${totalIncorrectOwnership} out of ${totalNumberOfRisks} (${getPercentage(totalNumberOfRisks, totalIncorrectOwnership)}%) IS risks have incorrect owners assigned.`,
            },
          ],
          requiredActionsText:
            "Assign risks to the correct owner according to AFIRM.",
        },
        timelyRiskManagementChipText: "Weighted Score: 40%",
        timelyRiskManagementSubtitleLeftSideText: `${totalDelayedRisks} out of ${totalNumberOfRisks} (${getPercentage(totalNumberOfRisks, totalDelayedRisks)}%) IS risks are delayed in process, meaning not in monitoring and reporting within 30 calendar days from identification/re-assessment.`,
        timelyRiskManagementSubtitleRightSideText: `Among ${responseDecision?.mitigate ?? 0} IS risks with response decision “Mitigate”, ${totalOverdueRisks} (${getPercentage(responseDecision?.mitigate ?? 0, totalOverdueRisks)}%) risks have overdue Mitigation Plan. \n\n ${responseDecision?.noSelection ?? 0} risks has no response decision defined yet.`,
        delayDonutChartProps: {
          labelsOffset: 22,
          enableDataLabels: false,
          enableLegend: false,
          series: [totalDelayedRisks, totalNumberOfRisks - totalDelayedRisks],
          labelsTitle: "Delay",
          backgroundColor: [theme.palette.primary.main, "#94CADC"],
          labels: ["Delayed Process", "On Track Process"],
          background: "#f5f5f5",
          showLabels: true,

          toolTipEnabled: true,
          toolTipLabels: ["Delayed Process", "On Track Process"],
        },
        responseDonutChartProps: {
          labelsOffset: 22,
          enableDataLabels: false,
          enableLegend: false,
          series: [
            responseDecision?.noSelection ?? 0,
            responseDecision?.mitigate ?? 0,
            responseDecision?.accept ?? 0,
            responseDecision?.close ?? 0,
          ],
          labelsTitle: "Response Decision",
          backgroundColor: [
            responseDecision?.noSelection ? "#94CADC" : "",
            responseDecision?.mitigate ? theme.palette.primary.main : "",
            responseDecision?.accept ? theme.palette.secondary.main : "",
            responseDecision?.close ? "#466D7A" : "",
          ],
          labels: [
            responseDecision?.noSelection ? "No Decision" : "",
            responseDecision?.mitigate ? "Mitigate" : "",
            responseDecision?.accept ? "Accept" : "",
            responseDecision?.close ? "Close" : "",
          ],
          background: "#f5f5f5",
          showLabels: true,
          toolTipEnabled: true,
          toolTipLabels: ["No Decision", "Mitigate", "Accept", "Close"],
        },
        mitigationDonutChartProps: {
          labelsOffset: 22,
          enableDataLabels: false,
          enableLegend: false,
          series: [
            totalOverdueRisks,
            (state.dashboard?.responseDecision?.mitigate ?? 0) -
              totalOverdueRisks,
          ],
          labelsTitle: "Mitigation",
          backgroundColor: [
            "#94CADC",
            theme.palette.primary.main,
            theme.palette.secondary.main,
          ],
          labels: ["Overdue", "On Track"],
          background: "#f5f5f5",
          showLabels: true,
          toolTipEnabled: true,
          toolTipLabels: ["Overdue", "On Track"],
        },
      };
    });

    const groupOverviewTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "groupOverviewTitle",
        xs: 12,
      },
    });
    groupOverviewTitle.mapState(() => ({
      title: "Overview of OE ISRR Health Indicator",
    }));

    const groupKeyMessage = view.addComponent({
      component: SimpleTextCardWithTitle,
      layoutProps: { id: "groupKeyMessage" },
    });
    groupKeyMessage.mapState(() => ({
      title: "Key Message",
      text: "The OE ISRR HI is part of respective OE’s IS Scorecard report. The risks’ details supporting the ISRR HI score are included in respective OE IS reports.",
      cardProps: { sx: { background: "#f5f5f5", height: "100%", ml: 2 } },
    }));

    const tableLeft = view.addComponent({
      component: OverviewTable,
      layoutProps: {
        id: "regionTable1",
      },
    });

    tableLeft.mapState((state) => {
      const transformedData = transform(
        state?.oes
          ?.sort((oe1, oe2) => oe1.division.localeCompare(oe2.division))
          .slice(0, 28) ?? []
      );
      for (const data of transformedData) {
        for (const oe of data.oes) {
          oe.chipColor =
            oe.score === 5
              ? RISK_TYPE_DETAILS.verylow.color
              : oe.score === 4
                ? RISK_TYPE_DETAILS.low.color
                : oe.score === 3
                  ? RISK_TYPE_DETAILS.moderate.color
                  : oe.score === 2
                    ? RISK_TYPE_DETAILS.high.color
                    : RISK_TYPE_DETAILS.veryHigh.color;
          oe.textColor = "#fff";
        }
      }
      return {
        entries: transformedData,
      };
    });

    const tableRight = view.addComponent({
      component: OverviewTable,
      layoutProps: {
        id: "regionTable2",
      },
    });

    tableRight.mapState((state) => {
      const transformedData = transform(
        state?.oes
          ?.sort((oe1, oe2) => oe1.division.localeCompare(oe2.division))
          .slice(29) ?? []
      );
      for (const data of transformedData) {
        for (const oe of data.oes) {
          oe.chipColor =
            oe.score === 5
              ? RISK_TYPE_DETAILS.verylow.color
              : oe.score === 4
                ? RISK_TYPE_DETAILS.low.color
                : oe.score === 3
                  ? RISK_TYPE_DETAILS.moderate.color
                  : oe.score === 2
                    ? RISK_TYPE_DETAILS.high.color
                    : RISK_TYPE_DETAILS.veryHigh.color;
          oe.textColor = "#fff";
        }
      }
      return {
        entries: transformedData,
      };
    });
  }

  private buildAggregatedViewSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const aggregateHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "aggregateHeader",
      },
    });

    aggregateHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[2],
      id: DASHBOARD_PAGE_SECTIONS[2],
      sx: { scrollMarginTop: "80px" },
    }));

    const allISRiskCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "allISRiskCardTitle",
        xs: 12,
      },
    });
    allISRiskCardTitle.mapState(() => ({
      title: "IS Risks by Risk Score",
    }));
    const allOarCard = view.addComponent({
      component: DonutChart,
      layoutProps: {
        marginX: 2,
        marginY: 1,
        id: "allOaRCard",
      },
    });

    allOarCard.mapState((state) => {
      const overallScore: RiskScore =
        state.dashboard?.riskByScore?.overall ?? ({} as RiskScore);
      const { isms, ba, itom, itService } = state.dashboard?.riskByScore ?? {};

      const { maxCategory, maxSum } = findCategoryWithHighestSum({
        isms,
        ba,
        itom,
        itService,
      });

      const firstSectionKeyMessage =
        maxSum > 0
          ? `${maxSum} out of ${state.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, maxSum)}%) IS risks are related to ${maxCategory.toUpperCase()}.`
          : "";

      const highs = overallScore.high + overallScore.veryHigh;
      const secondSectionKeyMessage =
        overallScore.high === 0 && overallScore.veryHigh === 0
          ? `All IS risks are rated as Very Low, Low, or Moderate. No High or Very High risks recorded.`
          : highs === 1
            ? `Out of ${state.dashboard?.totalNumberOfRisks ?? 0} IS risks, ${overallScore?.high ?? 0} risk (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, overallScore?.high ?? 0)}%) is rated as High and ${overallScore?.veryHigh ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, overallScore?.veryHigh ?? 0)}%) is rated as very high`
            : `Out of ${state.dashboard?.totalNumberOfRisks ?? 0} IS risks, ${(overallScore?.high ?? 0) + (overallScore?.veryHigh ?? 0)} risks (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, (overallScore?.high ?? 0) + (overallScore?.veryHigh ?? 0))}%) are rated as High and Very High.`;

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `All OaR (# of risks)`,
        titleOfSideText: "Key message",
        sideText: formatListWithBullets([
          firstSectionKeyMessage,
          secondSectionKeyMessage,
        ]),
        background: "#F5F5F5",
        series: [
          overallScore["noSelection"] ?? 0,
          overallScore["veryLow"] ?? 0,
          overallScore["low"] ?? 0,
          overallScore["moderate"] ?? 0,
          overallScore["high"] ?? 0,
          overallScore["veryHigh"] ?? 0,
        ],
        fallbackText: "N/A",
        labelsOffset: 22,
        backgroundColor: [
          RISK_TYPE_DETAILS.noSelection.color,
          RISK_TYPE_DETAILS.verylow.color,
          RISK_TYPE_DETAILS.low.color,
          RISK_TYPE_DETAILS.moderate.color,
          RISK_TYPE_DETAILS.high.color,
          RISK_TYPE_DETAILS.veryHigh.color,
        ],
        enableDataLabels: false,
        enableLegend: false,
        showLabels: true,
        toolTipEnabled: true,
        toolTipLabels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
        labels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
      };
    });

    const ismsOaRCard = view.addComponent({
      component: DonutChart,
      layoutProps: {
        xs: 3,
        id: "ISMSOaRCard",
      },
    });

    ismsOaRCard.mapState((state) => {
      const ismsScore: RiskScore =
        state.dashboard?.riskByScore?.isms ?? ({} as RiskScore);

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `ISMS OaR (# of risks)`,
        labelsOffset: 22,
        series: [
          ismsScore?.["noSelection"] ?? 0,
          ismsScore?.["veryLow"] ?? 0,
          ismsScore?.["low"] ?? 0,
          ismsScore?.["moderate"] ?? 0,
          ismsScore?.["high"] ?? 0,
          ismsScore?.["veryHigh"] ?? 0,
        ],

        fallbackText: "N/A",

        backgroundColor: [
          RISK_TYPE_DETAILS.noSelection.color,
          RISK_TYPE_DETAILS.verylow.color,
          RISK_TYPE_DETAILS.low.color,
          RISK_TYPE_DETAILS.moderate.color,
          RISK_TYPE_DETAILS.high.color,
          RISK_TYPE_DETAILS.veryHigh.color,
        ],

        legendPosition: "left",
        toolTipEnabled: true,
        enableDataLabels: false,
        enableLegend: false,
        showLabels: true,
        toolTipLabels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
        labels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
      };
    });

    const baOaRCard = view.addComponent({
      component: DonutChart,
      layoutProps: {
        id: "BAOaRCard",
      },
    });

    baOaRCard.mapState((state) => {
      const baScore: RiskScore =
        state.dashboard?.riskByScore?.ba ?? ({} as RiskScore);

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `BA OaR (# of risks)`,
        labelsOffset: 22,
        series: [
          baScore?.["noSelection"] ?? 0,
          baScore?.["veryLow"] ?? 0,
          baScore?.["low"] ?? 0,
          baScore?.["moderate"] ?? 0,
          baScore?.high ?? 0,
          baScore?.["veryHigh"] ?? 0,
        ],
        fallbackText: "N/A",
        backgroundColor: [
          RISK_TYPE_DETAILS.noSelection.color,
          RISK_TYPE_DETAILS.verylow.color,
          RISK_TYPE_DETAILS.low.color,
          RISK_TYPE_DETAILS.moderate.color,
          RISK_TYPE_DETAILS.high.color,
          RISK_TYPE_DETAILS.veryHigh.color,
        ],
        legendPosition: "left",
        toolTipEnabled: true,
        enableDataLabels: false,
        enableLegend: false,
        showLabels: true,
        toolTipLabels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
        labels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
      };
    });
    const itomOaRCard = view.addComponent({
      component: DonutChart,
      layoutProps: {
        id: "ITOMOaRCard",
      },
    });

    itomOaRCard.mapState((state) => {
      const itomScore: RiskScore =
        state.dashboard?.riskByScore?.itom ?? ({} as RiskScore);

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        labelsOffset: 22,
        title: `ITOM OaR (# of risks)`,
        series: [
          itomScore?.["noSelection"] ?? 0,
          itomScore?.["veryLow"] ?? 0,
          itomScore?.["low"] ?? 0,
          itomScore?.["moderate"] ?? 0,
          itomScore?.high ?? 0,
          itomScore?.["veryHigh"] ?? 0,
        ],
        fallbackText: "N/A",
        backgroundColor: [
          RISK_TYPE_DETAILS.noSelection.color,
          RISK_TYPE_DETAILS.verylow.color,
          RISK_TYPE_DETAILS.low.color,
          RISK_TYPE_DETAILS.moderate.color,
          RISK_TYPE_DETAILS.high.color,
          RISK_TYPE_DETAILS.veryHigh.color,
        ],
        legendPosition: "left",
        toolTipEnabled: true,
        enableDataLabels: false,
        enableLegend: false,
        showLabels: true,
        toolTipLabels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
        labels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
      };
    });

    const serviceOaRCard = view.addComponent({
      component: DonutChart,
      layoutProps: {
        id: "IT-ServiceOaRCard",
      },
    });

    serviceOaRCard.mapState((state) => {
      const serviceScore: RiskScore =
        state.dashboard?.riskByScore?.itService ?? ({} as RiskScore);

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: `IT-Service OaR (# of risks)`,
        labelsOffset: 22,
        series: [
          serviceScore["noSelection"] ?? 0,
          serviceScore["veryLow"] ?? 0,
          serviceScore["low"] ?? 0,
          serviceScore["moderate"] ?? 0,
          serviceScore.high ?? 0,
          serviceScore["veryHigh"] ?? 0,
        ],
        fallbackText: "N/A",
        backgroundColor: [
          RISK_TYPE_DETAILS.noSelection.color,
          RISK_TYPE_DETAILS.verylow.color,
          RISK_TYPE_DETAILS.low.color,
          RISK_TYPE_DETAILS.moderate.color,
          RISK_TYPE_DETAILS.high.color,
          RISK_TYPE_DETAILS.veryHigh.color,
        ],
        legendPosition: "left",
        toolTipEnabled: true,
        enableDataLabels: false,
        enableLegend: false,
        showLabels: true,
        toolTipLabels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
        labels: [
          RISK_TYPE_DETAILS.noSelection.text,
          RISK_TYPE_DETAILS.verylow.text,
          RISK_TYPE_DETAILS.low.text,
          RISK_TYPE_DETAILS.moderate.text,
          RISK_TYPE_DETAILS.high.text,
          RISK_TYPE_DETAILS.veryHigh.text,
        ],
      };
    });

    const legend = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legend",
      },
    });

    legend.mapState(() => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });
  }

  private buildOwnershipSection(view: View<RrAppState, CompoundLayoutProps>) {
    const ownershipHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "ownershipHeader",
      },
    });

    ownershipHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[3],
      id: DASHBOARD_PAGE_SECTIONS[3],
      sx: { scrollMarginTop: "80px" },
    }));

    const ownershipCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "ownershipCardTitle",
        xs: 12,
      },
    });
    ownershipCardTitle.mapState(() => ({
      title: "Ownership of IS Risks",
    }));
    const ownershipKeyMessage = view.addComponent({
      component: IndentedTextCardWithTitle,
      layoutProps: {
        id: "ownershipKeyMessage",
      },
    });

    ownershipKeyMessage.mapState((state) => {
      const { isms, itom } =
        state.dashboard?.currentQuarterOwnership ?? ({} as IncorrectOwnership);
      const firstSectionKeyMessage = `According to AFIRM v3, the correct risk ownership per OaR is defined as follows:\n\n`;
      const keyMessagePartA = `•Risk Owner for ISMS and ITOM as object at risk: Board member in charge of IS and IT\n\n`;
      const keyMessagePartB = `•Risk Owner for BA: Business Owner\n\n`;
      const keyMessagePartC = `•Risk Owner for IT-Service: OE IT Function\n\n`;
      const keyMessagePartD = "Currently\n\n";
      const secondSectionKeyMessage = `•${isms ?? 0} out of ${state.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, isms)}) ISMS risks ${isms === 1 ? "has" : "have"} incorrect owners assigned\n\n`;

      const thirdSectionKeyMessage = `•${itom ?? 0} out of ${state.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, itom)}) ITOM risks ${itom === 1 ? "has" : "have"} incorrect owners assigned\n\n`;

      const ismsItomTotal = isms + itom;
      const fourthSectionKeyMessage =
        ismsItomTotal === 0
          ? "•There are no IS risks with incorrect owners.\n\n"
          : "";

      const fifthSectionKeyMessage = `•There is no direct mechanism to verify the correct ownership of BA and IT-Service risks; therefore, BA and IT-Service risk ownership is considered correct, unless the field is empty, which means no owner has been assigned.\n\n`;

      return {
        title: "Key Message",
        text: [
          { line: firstSectionKeyMessage, indent: false },
          { line: keyMessagePartA, indent: true },
          { line: keyMessagePartB, indent: true },
          { line: keyMessagePartC, indent: true },
          { line: keyMessagePartD, indent: false },
          { line: secondSectionKeyMessage, indent: true },
          { line: thirdSectionKeyMessage, indent: true },
          { line: fourthSectionKeyMessage, indent: true },
          { line: fifthSectionKeyMessage, indent: true },
        ],

        cardProps: {
          sx: {
            color: "black",
            background: "#f5f5f5",
            maxHeight: 500,
            overflow: "auto",
            ml: 2,
            "@media print": {
              maxHeight: 1000,
              overflow: "visible",
            },
          },
        },
        textProps: {
          sx: {
            "@media print": {
              maxHeight: 1000,
              overflow: "visible",
            },
          },
        },
      };
    });

    const ismsOarChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "ismsOarChart",
      },
    });

    ismsOarChart.mapState((state) => {
      const totalIsms =
        (state.dashboard?.riskByScore?.isms?.veryHigh ?? 0) +
        (state.dashboard?.riskByScore?.isms?.high ?? 0) +
        (state.dashboard?.riskByScore?.isms?.moderate ?? 0) +
        (state.dashboard?.riskByScore?.isms?.low ?? 0) +
        (state.dashboard?.riskByScore?.isms?.veryLow ?? 0) +
        (state.dashboard?.riskByScore?.isms?.noSelection ?? 0);
      const currentIsms = state.dashboard?.currentQuarterOwnership?.isms ?? 0;
      const pastIsms = state.dashboard?.pastQuarterOwnership?.isms ?? 0;
      const pastCorrectIsms =
        state.dashboard?.pastQuarterCorrectOwnership?.isms ?? 0;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "ISMS OaR",
        fallbackText: "N/A",
        barMaxValue: Math.max(
          currentIsms,
          pastIsms,
          pastCorrectIsms,
          totalIsms - currentIsms
        ),
        groupedSeries: [
          {
            series: [
              {
                labelsHeader: "Incorrectly Assigned",
                entries: [
                  {
                    value: currentIsms,
                    color: current.color,
                  },
                  {
                    value: pastIsms,
                    color: past.color,
                  },
                ],
              },
              {
                labelsHeader: "Correctly Assigned",
                entries: [
                  {
                    value: totalIsms - currentIsms,
                    color: current.color,
                  },
                  {
                    value: pastCorrectIsms,
                    color: past.color,
                  },
                ],
              },
            ],
          },
        ],
      };
    });

    const baOarChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "baOarChart",
      },
    });

    baOarChart.mapState((state) => {
      const totalBa =
        (state.dashboard?.riskByScore?.ba?.veryHigh ?? 0) +
        (state.dashboard?.riskByScore?.ba?.high ?? 0) +
        (state.dashboard?.riskByScore?.ba?.moderate ?? 0) +
        (state.dashboard?.riskByScore?.ba?.low ?? 0) +
        (state.dashboard?.riskByScore?.ba?.veryLow ?? 0) +
        (state.dashboard?.riskByScore?.ba?.noSelection ?? 0);

      const currentBa = state.dashboard?.currentQuarterOwnership?.ba ?? 0;
      const pastBa = state.dashboard?.pastQuarterOwnership?.ba ?? 0;
      const pastCorrectBa =
        state.dashboard?.pastQuarterCorrectOwnership?.ba ?? 0;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "BA OaR",
        fallbackText: "N/A",
        barMaxValue: Math.max(
          currentBa,
          pastBa,
          pastCorrectBa,
          totalBa - currentBa
        ),
        groupedSeries: [
          {
            series: [
              {
                labelsHeader: "Incorrectly Assigned",
                entries: [
                  {
                    value: currentBa,

                    color: current.color,
                  },
                  {
                    value: pastBa,
                    color: past.color,
                  },
                ],
              },
              {
                labelsHeader: "Correctly Assigned",
                entries: [
                  {
                    value: totalBa - currentBa,
                    color: current.color,
                  },
                  {
                    value: pastCorrectBa,
                    color: past.color,
                  },
                ],
              },
            ],
          },
        ],
      };
    });

    const itomOarChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "itomOarChart",
      },
    });
    itomOarChart.mapState((state) => {
      const totalItom =
        (state.dashboard?.riskByScore?.itom?.veryHigh ?? 0) +
        (state.dashboard?.riskByScore?.itom?.high ?? 0) +
        (state.dashboard?.riskByScore?.itom?.moderate ?? 0) +
        (state.dashboard?.riskByScore?.itom?.low ?? 0) +
        (state.dashboard?.riskByScore?.itom?.veryLow ?? 0) +
        (state.dashboard?.riskByScore?.itom?.noSelection ?? 0);
      const currentItom = state.dashboard?.currentQuarterOwnership?.itom ?? 0;
      const pastItom = state.dashboard?.pastQuarterOwnership?.itom ?? 0;
      const pastCorrectItom =
        state.dashboard?.pastQuarterCorrectOwnership?.itom ?? 0;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "ITOM OaR",
        barMaxValue: Math.max(
          currentItom,
          pastItom,
          pastCorrectItom,
          totalItom - currentItom
        ),
        groupedSeries: [
          {
            series: [
              {
                labelsHeader: "Incorrectly Assigned",
                entries: [
                  {
                    value: currentItom,

                    color: current.color,
                  },
                  {
                    value: pastItom,
                    color: past.color,
                  },
                ],
              },
              {
                labelsHeader: "Correctly Assigned",
                entries: [
                  {
                    value: totalItom - currentItom,
                    color: current.color,
                  },
                  {
                    value: pastCorrectItom,
                    color: past.color,
                  },
                ],
              },
            ],
          },
        ],
        fallbackText: "N/A",
      };
    });

    const serviceOarChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "serviceOarChart",
      },
    });

    serviceOarChart.mapState((state) => {
      const totalService =
        (state.dashboard?.riskByScore?.itService?.veryHigh ?? 0) +
        (state.dashboard?.riskByScore?.itService?.high ?? 0) +
        (state.dashboard?.riskByScore?.itService?.moderate ?? 0) +
        (state.dashboard?.riskByScore?.itService?.low ?? 0) +
        (state.dashboard?.riskByScore?.itService?.veryLow ?? 0) +
        (state.dashboard?.riskByScore?.itService?.noSelection ?? 0);
      const currentService =
        state.dashboard?.currentQuarterOwnership?.itService ?? 0;
      const pastService = state.dashboard?.pastQuarterOwnership?.itService ?? 0;
      const pastCorrectItService =
        state.dashboard?.pastQuarterCorrectOwnership?.itService ?? 0;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "IT-Service OaR",
        barMaxValue: Math.max(
          currentService,
          pastService,
          pastCorrectItService,
          totalService - currentService
        ),
        groupedSeries: [
          {
            series: [
              {
                labelsHeader: "Incorrectly Assigned",
                entries: [
                  {
                    value: currentService,

                    color: current.color,
                  },
                  {
                    value: pastService,
                    color: past.color,
                  },
                ],
              },
              {
                labelsHeader: "Correctly Assigned",
                entries: [
                  {
                    value: totalService - currentService,
                    color: current.color,
                  },
                  {
                    value: pastCorrectItService,
                    color: past.color,
                  },
                ],
              },
            ],
          },
        ],
        fallbackText: "N/A",
      };
    });

    const legendOwnershipRisks = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legendOwnershipRisks",
      },
    });

    legendOwnershipRisks.mapState((state) => {
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "Date as of",
        entries: [
          ...(state.dashboard?.currentQuarter
            ? [
                {
                  title: state.dashboard?.currentQuarter,
                  color: current.color,
                },
              ]
            : []),
          ...(state.dashboard?.pastQuarter
            ? [
                {
                  title: state.dashboard?.pastQuarter,
                  color: past.color,
                },
              ]
            : []),
        ],
      };
    });
  }

  private buildTimelyRiskManagementSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const timelyRiskManagementHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "timelyRiskManagementHeader",
      },
    });

    timelyRiskManagementHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[4],
      id: DASHBOARD_PAGE_SECTIONS[4],
      sx: { scrollMarginTop: "80px" },
    }));

    const timelyRiskCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "timelyRiskCardTitle",
        xs: 12,
      },
    });
    timelyRiskCardTitle.mapState(() => ({
      title: "Delayed Process of IS Risks grouped by risk score",
    }));

    const timelyRiskOaRChart = view.addComponent({
      component: GroupedBarChart,
      layoutProps: {
        id: "timelyRiskOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    timelyRiskOaRChart.mapState((state) => {
      const { noSelection, verylow, low, moderate, high, veryHigh } =
        RISK_TYPE_DETAILS;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      const currentOverall =
        state.dashboard?.currentQuarterDelayed?.overall ?? ({} as RiskScore);
      const pastOverall =
        state.dashboard?.pastQuarterDelayed?.overall ?? ({} as RiskScore);
      const totalDelayedRisks =
        currentOverall.high +
        currentOverall.veryHigh +
        currentOverall.low +
        currentOverall.moderate +
        currentOverall.veryLow +
        currentOverall.noSelection;
      // Generate first section key message
      const firstSectionKeyMessage =
        "Delayed process refers to risks that are not moved to Monitoring & Reporting within 30 calendar days from Identification or Re-assessment.";

      const secondSectionKeyMessage =
        totalDelayedRisks > 0
          ? `${totalDelayedRisks} out of ${state.dashboard?.totalNumberOfRisks ?? 0} IS risks (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, totalDelayedRisks)}) ${totalDelayedRisks === 1 ? "is" : "are"} delayed in process`
          : "There are no IS risks with delayed process of risks.";
      const highs = currentOverall.high + currentOverall.veryHigh;
      const thirdSectionKeyMessage =
        highs > 0
          ? `Among those, ${currentOverall.veryHigh} and ${currentOverall.high} are rated as Very High and High respectively.`
          : "None of them are rated as High or Very High.";
      return {
        title: "All OaR",
        titleOfTopText: "Key Message",
        cardProps: { sx: { background: "#f5f5f5" } },
        colors: [current.color, past.color],
        labelsColors: ["#000"],
        categoriesColors: [
          veryHigh.color,
          high.color,
          moderate.color,
          low.color,
          verylow.color,
          noSelection.color,
        ],
        sideText: formatListWithBullets([
          firstSectionKeyMessage,
          secondSectionKeyMessage,
          thirdSectionKeyMessage,
        ]),
        groupedSeries: [
          {
            series: [
              {
                name: state.dashboard?.currentQuarter ?? "",
                value: [
                  currentOverall?.veryHigh ?? 0,
                  currentOverall?.high ?? 0,
                  currentOverall?.moderate ?? 0,
                  currentOverall?.low ?? 0,
                  currentOverall?.veryLow ?? 0,
                  currentOverall?.noSelection ?? 0,
                ],
              },
              {
                name: state.dashboard?.pastQuarter ?? "",
                value: [
                  pastOverall?.veryHigh ?? 0,
                  pastOverall?.high ?? 0,
                  pastOverall?.moderate ?? 0,
                  pastOverall?.low ?? 0,
                  pastOverall?.veryLow ?? 0,
                  pastOverall?.noSelection ?? 0,
                ],
              },
            ],
            categories: [
              veryHigh.text,
              high.text,
              moderate.text,
              low.text,
              verylow.text,
              noSelection.text,
            ],
          },
        ],
      };
    });

    const timelyRiskismsOaRChart = view.addComponent({
      component: GroupedBarChart,
      layoutProps: {
        id: "timelyRiskismsOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    timelyRiskismsOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      const currentDelayed =
        state.dashboard?.currentQuarterDelayed?.isms ?? ({} as RiskScore);
      const pastDelayed =
        state.dashboard?.pastQuarterDelayed?.isms ?? ({} as RiskScore);

      return {
        title: "ISMS OaR",
        colors: [current.color, past.color],
        categoriesColors: [
          veryHigh.color,
          high.color,
          moderate.color,
          low.color,
          verylow.color,
          noSelection.color,
        ],
        fallbackText: "N/A",
        labelsColors: ["#000"],
        groupedSeries: [
          {
            series: [
              {
                name: state.dashboard?.currentQuarter ?? "",
                value: [
                  currentDelayed?.veryHigh ?? 0,
                  currentDelayed?.high ?? 0,
                  currentDelayed?.moderate ?? 0,
                  currentDelayed?.low ?? 0,
                  currentDelayed?.veryLow ?? 0,
                  currentDelayed?.noSelection ?? 0,
                ],
              },
              {
                name: state.dashboard?.pastQuarter ?? "",
                value: [
                  pastDelayed?.veryHigh ?? 0,
                  pastDelayed?.high ?? 0,
                  pastDelayed?.moderate ?? 0,
                  pastDelayed?.low ?? 0,
                  pastDelayed?.veryLow ?? 0,
                  pastDelayed?.noSelection ?? 0,
                ],
              },
            ],
            categories: [
              veryHigh.text,
              high.text,
              moderate.text,
              low.text,
              verylow.text,
              noSelection.text,
            ],
          },
        ],
      };
    });
    const timelyRiskBaOaRChart = view.addComponent({
      component: GroupedBarChart,
      layoutProps: {
        id: "timelyRiskBaOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    timelyRiskBaOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const { past, current } = RISK_CURRENT_PAST_COLOR;

      const currentDelayed =
        state.dashboard?.currentQuarterDelayed?.ba ?? ({} as RiskScore);
      const pastDelayed =
        state.dashboard?.pastQuarterDelayed?.ba ?? ({} as RiskScore);

      return {
        title: "BA OaR",
        colors: [current.color, past.color],
        labelsColors: ["#000"],
        categoriesColors: [
          veryHigh.color,
          high.color,
          moderate.color,
          low.color,
          verylow.color,
          noSelection.color,
        ],
        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                name: state.dashboard?.currentQuarter ?? "",
                value: [
                  currentDelayed?.veryHigh ?? 0,
                  currentDelayed?.high ?? 0,
                  currentDelayed?.moderate ?? 0,
                  currentDelayed?.low ?? 0,
                  currentDelayed?.veryLow ?? 0,
                  currentDelayed?.noSelection ?? 0,
                ],
              },
              {
                name: state.dashboard?.pastQuarter ?? "",
                value: [
                  pastDelayed?.veryHigh ?? 0,
                  pastDelayed?.high ?? 0,
                  pastDelayed?.moderate ?? 0,
                  pastDelayed?.low ?? 0,
                  pastDelayed?.veryLow ?? 0,
                  pastDelayed?.noSelection ?? 0,
                ],
              },
            ],
            categories: [
              veryHigh.text,
              high.text,
              moderate.text,
              low.text,
              verylow.text,
              noSelection.text,
            ],
          },
        ],
      };
    });

    const timelyRiskItomOaRChart = view.addComponent({
      component: GroupedBarChart,
      layoutProps: {
        id: "timelyRiskItomOaRChart",
      },
    });

    timelyRiskItomOaRChart.mapState((state) => {
      const currentDelayed =
        state.dashboard?.currentQuarterDelayed?.itom ?? ({} as RiskScore);
      const pastDelayed =
        state.dashboard?.pastQuarterDelayed?.itom ?? ({} as RiskScore);
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "ITOM OaR",
        fallbackText: "N/A",
        colors: [current.color, past.color],
        categoriesColors: [
          veryHigh.color,
          high.color,
          moderate.color,
          low.color,
          verylow.color,
          noSelection.color,
        ],
        labelsColors: ["#000"],
        groupedSeries: [
          {
            series: [
              {
                name: state.dashboard?.currentQuarter ?? "",
                value: [
                  currentDelayed?.veryHigh ?? 0,
                  currentDelayed?.high ?? 0,
                  currentDelayed?.moderate ?? 0,
                  currentDelayed?.low ?? 0,
                  currentDelayed?.veryLow ?? 0,
                  currentDelayed?.noSelection ?? 0,
                ],
              },
              {
                name: state.dashboard?.pastQuarter ?? "",
                value: [
                  pastDelayed?.veryHigh ?? 0,
                  pastDelayed?.high ?? 0,
                  pastDelayed?.moderate ?? 0,
                  pastDelayed?.low ?? 0,
                  pastDelayed?.veryLow ?? 0,
                  pastDelayed?.noSelection ?? 0,
                ],
              },
            ],
            categories: [
              veryHigh.text,
              high.text,
              moderate.text,
              low.text,
              verylow.text,
              noSelection.text,
            ],
          },
        ],
      };
    });

    const timelyRiskServiceOaRChart = view.addComponent({
      component: GroupedBarChart,
      layoutProps: {
        id: "timelyRiskServiceOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    timelyRiskServiceOaRChart.mapState((state) => {
      const currentDelayed =
        state.dashboard?.currentQuarterDelayed?.itService ?? ({} as RiskScore);
      const pastDelayed =
        state.dashboard?.pastQuarterDelayed?.itService ?? ({} as RiskScore);
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "IT-Service OaR",
        fallbackText: "N/A",
        labelsColors: ["#000"],
        colors: [current.color, past.color],
        categoriesColors: [
          veryHigh.color,
          high.color,
          moderate.color,
          low.color,
          verylow.color,
          noSelection.color,
        ],
        groupedSeries: [
          {
            series: [
              {
                name: state.dashboard?.currentQuarter ?? "",
                value: [
                  currentDelayed?.veryHigh ?? 0,
                  currentDelayed?.high ?? 0,
                  currentDelayed?.moderate ?? 0,
                  currentDelayed?.low ?? 0,
                  currentDelayed?.veryLow ?? 0,
                  currentDelayed?.noSelection ?? 0,
                ],
              },
              {
                name: state.dashboard?.pastQuarter ?? "",
                value: [
                  pastDelayed?.veryHigh ?? 0,
                  pastDelayed?.high ?? 0,
                  pastDelayed?.moderate ?? 0,
                  pastDelayed?.low ?? 0,
                  pastDelayed?.veryLow ?? 0,
                  pastDelayed?.noSelection ?? 0,
                ],
              },
            ],
            categories: [
              veryHigh.text,
              high.text,
              moderate.text,
              low.text,
              verylow.text,
              noSelection.text,
            ],
          },
        ],
      };
    });

    const timelyRiskLegend = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "timelyRiskLegend",
      },
    });

    timelyRiskLegend.mapState((state) => {
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "Risk Score",
        entries: [
          ...(state.dashboard?.currentQuarter
            ? [
                {
                  title: state.dashboard?.currentQuarter,
                  color: current.color,
                },
              ]
            : []),
          ...(state.dashboard?.pastQuarter
            ? [
                {
                  title: state.dashboard?.pastQuarter,
                  color: past.color,
                },
              ]
            : []),
        ],
      };
    });

    const timelyOverdueRiskCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "timelyOverdueRiskCardTitle",
        xs: 12,
      },
    });
    timelyOverdueRiskCardTitle.mapState(() => ({
      cardProps: {
        sx: {
          "@media print": {
            pageBreakBefore: "always",
          },
        },
      },
      title:
        "IS Risks with overdue Mitigation Plans by Est. Completion Date and Risk Score",
    }));

    const allISOverdueRiskChart = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "allISOverdueRiskChart",
        marginX: 2,
        marginY: 1,
      },
    });

    allISOverdueRiskChart.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overdueMitigation =
        state.dashboard?.overallOverdueMitigation ??
        ({} as ScoreForAdditionalAttentionPoints);

      const totalRisks =
        overdueMitigation?.veryHigh
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) +
        overdueMitigation?.high
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) +
        overdueMitigation?.moderate
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) +
        overdueMitigation?.low
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) +
        overdueMitigation?.veryLow
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0) +
        overdueMitigation?.noSelection
          ?.map((r) => r.count)
          ?.reduce((acc, curr) => acc + curr, 0);
      const firstSectionKeyMessage =
        totalRisks > 0
          ? `Out of ${state.dashboard?.responseDecision?.mitigate ?? 0} risks with response decision “Mitigate”, there are ${totalRisks} (${getPercentage(state.dashboard?.responseDecision?.mitigate ?? 0, totalRisks)}%) risks with overdue mitigation plans. Out of these there are ${overdueMitigation?.veryHigh.map((a) => a.count).reduce((acc, curr) => acc + curr, 0)} Very High, ${overdueMitigation?.high.map((a) => a.count).reduce((acc, curr) => acc + curr, 0)} High risks.`
          : "There are no IS risks with overdue mitigation plans.";
      return {
        cardProps: {
          sx: {
            flex: 1,
            background: "#f5f5f5",
          },
        },
        title: "All OaR",
        titleOfTopText: "Key Message",
        topText: firstSectionKeyMessage,
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overdueMitigation?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  overdueMitigation?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value:
                  overdueMitigation?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overdueMitigation?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  overdueMitigation?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  overdueMitigation?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overdueMitigation?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const overdueRiskismsOaRChart = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "overdueRiskismsOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    overdueRiskismsOaRChart.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overdueMitigation =
        state.dashboard?.ismsOverdueMitigation ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ISMS OaR",
        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overdueMitigation?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  overdueMitigation?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value:
                  overdueMitigation?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overdueMitigation?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  overdueMitigation?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  overdueMitigation?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overdueMitigation?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const overdueRiskBaOaRChart = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "overdueRiskBaOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    overdueRiskBaOaRChart.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overdueMitigation =
        state.dashboard?.baOverdueMitigation ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "BA OaR",
        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overdueMitigation?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  overdueMitigation?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value:
                  overdueMitigation?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overdueMitigation?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  overdueMitigation?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  overdueMitigation?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overdueMitigation?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const overdueRiskItomOaRChart = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "overdueRiskItomOaRChart",
      },
    });

    overdueRiskItomOaRChart.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overdueMitigation =
        state.dashboard?.itomOverdueMitigation ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ITOM OaR",
        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overdueMitigation?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  overdueMitigation?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value:
                  overdueMitigation?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overdueMitigation?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  overdueMitigation?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  overdueMitigation?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overdueMitigation?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const overdueRiskServiceOaRChart = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "overdueRiskServiceOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    overdueRiskServiceOaRChart.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overdueMitigation =
        state.dashboard?.itServiceOverdueMitigation ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "IT-Service OaR",
        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overdueMitigation?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  overdueMitigation?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value:
                  overdueMitigation?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overdueMitigation?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  overdueMitigation?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  overdueMitigation?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overdueMitigation?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const overdueRiskLegend = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "overdueRiskLegend",
      },
    });

    overdueRiskLegend.mapState(() => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });
  }

  private buildAcceptedRiskSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const acceptedRiskManagementHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "acceptedRiskManagementHeader",
      },
    });

    acceptedRiskManagementHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[5],
      id: DASHBOARD_PAGE_SECTIONS[5],
      sx: { scrollMarginTop: "80px" },
    }));

    const acceptedRiskCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "acceptedRiskCardTitle",
        xs: 12,
      },
    });
    acceptedRiskCardTitle.mapState(() => ({
      title: "IS Risks with Response decision Accept",
    }));

    const acceptedAllISRiskChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "acceptedAllISRiskChart",
        marginX: 2,
        marginY: 1,
      },
    });

    acceptedAllISRiskChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const overall: RiskScore =
        state.dashboard?.acceptedRisks?.overall ?? ({} as RiskScore);

      const totalRisks =
        overall.high +
        overall.veryHigh +
        overall.low +
        overall.moderate +
        overall.veryLow +
        overall.noSelection;
      const totalHighs = overall.high + overall.veryHigh;
      const firstSectionKeyMessage =
        totalRisks > 0
          ? `${totalRisks} out of ${state.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, totalRisks)}%) IS Risks have response decision Accept.`
          : `There are no IS risks with response decision “Accept” in M&R.`;
      const secondSectionKeyMessage =
        totalHighs > 0
          ? `Amongst there are ${overall.veryHigh} Very High risks, ${overall.high} High risks`
          : "None of them are High or Very High risks.";

      return {
        title: "All OaR",
        titleOfSideText: "Key Message",
        cardProps: { sx: { background: "#f5f5f5" } },
        barMaxValue: Math.max(
          overall.veryHigh ?? 0,
          overall.high ?? 0,
          overall.moderate ?? 0,
          overall.low ?? 0,
          overall.veryLow ?? 0,
          overall.noSelection ?? 0
        ),

        sideText: formatListWithBullets([
          firstSectionKeyMessage,
          secondSectionKeyMessage,
        ]),
        groupedSeries: [
          {
            series: [
              {
                entries: [
                  {
                    value: overall.veryHigh ?? 0,
                    label: veryHigh.text,
                    color: veryHigh.color,
                  },
                  {
                    value: overall.high ?? 0,
                    label: high.text,
                    color: high.color,
                  },
                  {
                    value: overall.moderate ?? 0,
                    label: moderate.text,
                    color: moderate.color,
                  },
                  {
                    value: overall.low ?? 0,
                    label: low.text,
                    color: low.color,
                  },
                  {
                    value: overall.veryLow ?? 0,
                    label: verylow.text,
                    color: verylow.color,
                  },
                  {
                    value: overall.noSelection ?? 0,
                    label: noSelection.text,
                    color: noSelection.color,
                  },
                ],
              },
            ],
          },
        ],
      };
    });

    const acceptedRiskIsmsOaRChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "acceptedRiskIsmsOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    acceptedRiskIsmsOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const isms: RiskScore =
        state.dashboard?.acceptedRisks?.isms ?? ({} as RiskScore);
      return {
        title: "ISMS OaR",
        barMaxValue: Math.max(
          isms?.veryHigh ?? 0,
          isms?.high ?? 0,
          isms?.moderate ?? 0,
          isms?.low ?? 0,
          isms?.veryLow ?? 0,
          isms?.noSelection ?? 0
        ),

        fallbackText: "N/A",
        groupedSeries: [
          {
            series: [
              {
                entries: [
                  {
                    value: isms.veryHigh ?? 0,
                    label: veryHigh.text,
                    color: veryHigh.color,
                  },
                  {
                    value: isms.high ?? 0,
                    label: high.text,
                    color: high.color,
                  },
                  {
                    value: isms.moderate ?? 0,
                    label: moderate.text,
                    color: moderate.color,
                  },
                  {
                    value: isms.low ?? 0,
                    label: low.text,
                    color: low.color,
                  },
                  {
                    value: isms.veryLow ?? 0,
                    label: verylow.text,
                    color: verylow.color,
                  },
                  {
                    value: isms.noSelection ?? 0,
                    label: noSelection.text,
                    color: noSelection.color,
                  },
                ],
              },
            ],
          },
        ],
      };
    });

    const acceptedBaOaRChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "acceptedBaOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    acceptedBaOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const ba: RiskScore =
        state.dashboard?.acceptedRisks?.ba ?? ({} as RiskScore);
      return {
        title: "Ba OaR",
        barMaxValue: Math.max(
          ba.veryHigh ?? 0,
          ba.high ?? 0,
          ba.moderate ?? 0,
          ba.low ?? 0,
          ba.veryLow ?? 0,
          ba.noSelection ?? 0
        ),
        groupedSeries: [
          {
            series: [
              {
                entries: [
                  {
                    value: ba.veryHigh ?? 0,
                    label: veryHigh.text,
                    color: veryHigh.color,
                  },
                  { value: ba.high ?? 0, label: high.text, color: high.color },
                  {
                    value: ba.moderate ?? 0,
                    label: moderate.text,
                    color: moderate.color,
                  },
                  { value: ba.low ?? 0, label: low.text, color: low.color },
                  {
                    value: ba.veryLow ?? 0,
                    label: verylow.text,
                    color: verylow.color,
                  },
                  {
                    value: ba.noSelection ?? 0,
                    label: noSelection.text,
                    color: noSelection.color,
                  },
                ],
              },
            ],
          },
        ],
        fallbackText: "N/A",
      };
    });

    const acceptedRiskItomOaRChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "acceptedRiskItomOaRChart",
      },
    });

    acceptedRiskItomOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const itom: RiskScore =
        state.dashboard?.acceptedRisks?.itom ?? ({} as RiskScore);
      return {
        title: "ITOM OaR",
        barMaxValue: Math.max(
          itom.veryHigh ?? 0,
          itom.high ?? 0,
          itom.moderate ?? 0,
          itom.low ?? 0,
          itom.veryLow ?? 0,
          itom.noSelection ?? 0
        ),
        groupedSeries: [
          {
            series: [
              {
                entries: [
                  {
                    value: itom.veryHigh ?? 0,
                    label: veryHigh.text,
                    color: veryHigh.color,
                  },
                  {
                    value: itom.high ?? 0,
                    label: high.text,
                    color: high.color,
                  },
                  {
                    value: itom.moderate ?? 0,
                    label: moderate.text,
                    color: moderate.color,
                  },
                  {
                    value: itom.low ?? 0,
                    label: low.text,
                    color: low.color,
                  },
                  {
                    value: itom.veryLow ?? 0,
                    label: verylow.text,
                    color: verylow.color,
                  },
                  {
                    value: itom.noSelection ?? 0,
                    label: noSelection.text,
                    color: noSelection.color,
                  },
                ],
              },
            ],
          },
        ],
        fallbackText: "N/A",
      };
    });

    const acceptedRiskServiceOaRChart = view.addComponent({
      component: HorizontalBarChart,
      layoutProps: {
        id: "acceptedRiskServiceOaRChart",
        marginX: 2,
        marginY: 1,
      },
    });

    acceptedRiskServiceOaRChart.mapState((state) => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      const service: RiskScore =
        state.dashboard?.acceptedRisks?.itService ?? ({} as RiskScore);
      return {
        title: "IT-Service OaR",
        barMaxValue: Math.max(
          service?.veryHigh ?? 0,
          service?.high ?? 0,
          service?.moderate ?? 0,
          service?.low ?? 0,
          service?.veryLow ?? 0,
          service?.noSelection ?? 0
        ),
        groupedSeries: [
          {
            series: [
              {
                entries: [
                  {
                    value: service?.veryHigh ?? 0,
                    label: veryHigh.text,
                    color: veryHigh.color,
                  },
                  {
                    value: service?.high ?? 0,
                    label: high.text,
                    color: high.color,
                  },
                  {
                    value: service?.moderate ?? 0,
                    label: moderate.text,
                    color: moderate.color,
                  },
                  {
                    value: service?.low ?? 0,
                    label: low.text,
                    color: low.color,
                  },
                  {
                    value: service?.veryLow ?? 0,
                    label: verylow.text,
                    color: verylow.color,
                  },
                  {
                    value: service?.noSelection ?? 0,
                    label: noSelection.text,
                    color: noSelection.color,
                  },
                ],
              },
            ],
          },
        ],
        fallbackText: "N/A",
      };
    });

    const acceptedRiskLegend = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "acceptedRiskLegend",
      },
    });

    acceptedRiskLegend.mapState(() => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });
  }

  private buildIneffectiveControlsSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const ineffectiveControlsHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "ineffectiveControlsHeader",
      },
    });

    ineffectiveControlsHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[6],
      id: DASHBOARD_PAGE_SECTIONS[6],
      sx: { scrollMarginTop: "80px" },
    }));

    const ineffectiveControlsCardTitle = view.addComponent({
      component: IndentedTextCardWithTitle,
      layoutProps: {
        id: "ineffectiveControlsCardTitle",
        xs: 12,
      },
    });
    ineffectiveControlsCardTitle.mapState((state) => {
      const top2 = state.dashboard?.top10IneffectiveControls?.slice(0, 2) ?? [];
      const keyMessagePart1 =
        "Following Integrated Information Risk Management (IIRM) process, ineffective controls and their associated risks are identified, utilizing various identification methods (such as penetration test, external technical scan, IS compliance evidence, project resulting evidence, peer review), and associated risks are registered in Archer.\n\n";
      const keyMessage =
        top2.length === 2
          ? "The top 2 ineffective control objectives are:\n\n"
          : top2.length === 1
            ? "The top ineffective control objective is:\n\n"
            : "";

      const keyMessagePart2A = top2?.[0]
        ? `• ${top2[0]?.name} identified ${top2[0]?.total ?? 0} times as ineffective controls, of which ${top2[0]?.highAndVeryHigh ?? 0} times in risks with High and Very High score.`
        : "";
      const keyMessagePart2B = top2?.[1]
        ? `• ${top2[1]?.name} identified ${top2[1]?.total ?? 0} times as ineffective controls, of which ${top2[1]?.highAndVeryHigh ?? 0} times in risks with High and Very High score.`
        : "";

      const keyMessagePartA = "Follow-up actions:\n\n";
      const keyMessagePartB =
        "• Associated mitigation actions to risks are identified actions and are followed up until completion.\n\n";
      const keyMessagePartC =
        " • Group Information Security Initiative Measures in 2023 addresses some of the ineffective controls, for example:\n\n";
      const keyMessagePartD =
        " • IAM Transformation to strengthening IAM through a new Target Operating Model and stronger requirements in the AZ Corporate Rule Book, implementation in OEs is targeted until end of 2025 and closely monitored through IAM maturity level;\n\n";
      const keyMessagePartE =
        " • Third-Party Cyber Risk Managing (TPCRM), addressing information security risks related to the Third-Party. In addition to the TPCRM, Group Protection and Resilience had started the Third-Party Risk Management (TPRM) program covering various aspects of risks related to the Third-Party engagement;\n\n";
      const keyMessagePartF =
        " • Next Level Endpoint, Detection and Response (EDR) Service;\n\nAccelerate Keeper (secure password vault) adoption.\n\n";
      const keyMessagePartG =
        "• Insights will be used to drive additional remediation actions in 2024.`";
      return {
        title:
          "Ineffective IS Controls identified and being addressed through Measures",
        text: [
          { line: keyMessagePart1, indent: false },
          { line: keyMessage, indent: false },
          { line: keyMessagePart2A, indent: true },
          { line: keyMessagePart2B, indent: true },
          { line: keyMessagePartA, indent: false },
          { line: keyMessagePartB, indent: true },
          { line: keyMessagePartC, indent: true },
          { line: keyMessagePartD, indent: true },
          { line: keyMessagePartE, indent: true },
          { line: keyMessagePartF, indent: true },
          { line: keyMessagePartG, indent: true },
        ],
      };
    });

    const overviewNumberOfRisksCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "overviewNumberOfRisksCard",
      },
    });
    overviewNumberOfRisksCard.mapState((state) => {
      const { top10IneffectiveControls } = state.dashboard ?? {
        top10IneffectiveControls: [],
        top2IneffectiveControls: [],
      };
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        cardProps: {
          sx: {
            flex: 1,
            mx: 2,
          },
        },
        labelsColor: ["#000"],
        background: "#f5f5f5",
        maxChartWidth: 500,
        title: "Top 10 ineffective COBIT Objectives",
        groupedSeries: [
          {
            series: [
              {
                name: "Very high",
                value: top10IneffectiveControls
                  ?.slice(0, 2)
                  ?.map((ic) => ic?.highAndVeryHigh ?? 0),
              },
              {
                name: "Low",
                value: top10IneffectiveControls
                  ?.slice(0, 2)
                  ?.map((ic) => ic?.total - ic?.highAndVeryHigh ?? 0),
              },
            ],
            background: theme.palette.secondary.main,
            categories: top10IneffectiveControls
              ?.slice(0, 2)
              ?.map((entry) => entry.name),
          },
          {
            series: [
              {
                name: "Very high",
                value: top10IneffectiveControls
                  .slice(2)
                  .map((ic) => ic?.highAndVeryHigh ?? 0),
              },
              {
                name: "Low",
                value: top10IneffectiveControls
                  .slice(2)
                  .map((ic) => ic?.total - ic?.highAndVeryHigh ?? 0),
              },
            ],
            categories: top10IneffectiveControls
              .slice(2)
              .map((entry) => entry.name),
          },
        ],
        colors: [past.color, current.color],
      };
    });
    const firstRiskCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "firstRiskCard",
        marginX: 2,
        marginY: 1,
      },
    });

    firstRiskCard.mapState((state) => {
      const { top10IneffectiveControls, top2IneffectiveControls } =
        state.dashboard ?? {
          top10IneffectiveControls: [],
          top2IneffectiveControls: [],
        };
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        labelsColor: ["#000"],
        cardProps: {
          sx: {
            flex: 1,
            mx: 2,
          },
        },
        background: "#f5f5f5",
        maxChartWidth: 450,
        title: top10IneffectiveControls?.[0]?.name ?? "",
        groupedSeries: [
          {
            series: [
              {
                name: "Very high and high",
                value: top2IneffectiveControls
                  ?.filter(
                    (ic) =>
                      ic.keyInTop10 === top10IneffectiveControls?.[0]?.name
                  )
                  ?.map((ic) => ic?.highAndVeryHigh ?? 0),
              },
              {
                name: "Moderate, Low and Very Low",
                value: top2IneffectiveControls
                  ?.filter(
                    (ic) =>
                      ic?.keyInTop10 === top10IneffectiveControls?.[0]?.name
                  )
                  ?.map((ic) => ic?.total - ic.highAndVeryHigh ?? 0),
              },
            ],
            background: theme.palette.secondary.main,
            categories: top2IneffectiveControls
              ?.filter(
                (ic) => ic?.keyInTop10 === top10IneffectiveControls?.[0]?.name
              )
              ?.map((entry) => entry.name),
          },
        ],
        colors: [past.color, current.color],
      };
    });

    const secondRiskCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "secondRiskCard",
        marginX: 2,
        marginY: 1,
      },
    });

    secondRiskCard.mapState((state) => {
      const { top10IneffectiveControls, top2IneffectiveControls } =
        state.dashboard ?? {
          top10IneffectiveControls: [],
          top2IneffectiveControls: [],
        };
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        labelsColor: ["#000"],
        background: "#f5f5f5",
        maxChartWidth: 450,
        title: top10IneffectiveControls?.[1]?.name ?? "",
        groupedSeries: [
          {
            series: [
              {
                name: "Very high",
                value: top2IneffectiveControls
                  ?.filter(
                    (ic) =>
                      ic?.keyInTop10 === top10IneffectiveControls?.[1]?.name
                  )
                  ?.map((ic) => ic?.highAndVeryHigh ?? 0),
              },
              {
                name: "Low",
                value: top2IneffectiveControls
                  ?.filter(
                    (ic) =>
                      ic?.keyInTop10 === top10IneffectiveControls?.[1]?.name
                  )
                  .map((ic) => ic?.total - ic.highAndVeryHigh ?? 0),
              },
            ],
            background: theme.palette.secondary.main,
            categories: top2IneffectiveControls
              ?.filter(
                (ic) => ic.keyInTop10 === top10IneffectiveControls?.[1]?.name
              )
              .map((entry) => entry.name),
          },
        ],
        colors: [past.color, current.color],
      };
    });

    const ineffectiveControlsRisks = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "ineffectiveControlsRisks",
      },
    });

    ineffectiveControlsRisks.mapState(() => {
      const { past, current } = RISK_CURRENT_PAST_COLOR;
      return {
        title: "Risk Score",
        entries: [
          { title: "High and Very High Risks", color: past.color },
          { title: "Low, Very Low, Moderate Risks", color: current.color },
        ],
      };
    });
  }
  private buildAdditionalAttentionPoints(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const additionalPointsHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "additionalPointsHeader",
      },
    });

    additionalPointsHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[7],
      id: DASHBOARD_PAGE_SECTIONS[7],
      sx: { scrollMarginTop: "80px" },
    }));

    const risksAssessmentCyclesCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "risksAssessmentCyclesCardTitle",
        xs: 12,
      },
    });
    risksAssessmentCyclesCardTitle.mapState(() => ({
      title: "Risks with 3 or more Re-assessment cycles",
    }));

    const allIsRisksReassesmentCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "allIsRisksReassesmentCard",
        marginX: 2,
        marginY: 1,
      },
    });
    allIsRisksReassesmentCard.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const riskLevels = [
        "noSelection",
        "veryLow",
        "low",
        "moderate",
        "high",
        "veryHigh",
      ];
      const instanceCounts = ["seven", "six", "five", "four", "three"];
      const overallThreeCycles =
        state.dashboard?.overallThreeCycles ??
        ({} as ScoreForThreeOrMoreAssesments);

      let sumOfRisks = 0;
      for (const level of riskLevels) {
        for (const count of instanceCounts) {
          sumOfRisks +=
            overallThreeCycles?.[
              level as keyof ScoreForThreeOrMoreAssesments
            ]?.[
              count as keyof ScoreForThreeOrMoreAssesments[keyof ScoreForThreeOrMoreAssesments]
            ] ?? 0;
        }
      }

      const keyMessage =
        sumOfRisks >= 1
          ? `${sumOfRisks} out of ${state.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state.dashboard?.totalNumberOfRisks ?? 0, sumOfRisks)}%) IS risks have gone through 3 or more re-assessment cycles.
           \n\n Risks with 3 or more re-assessment cycles should be closely monitored and be considered for closure.`
          : "There are no IS risks with 3 or more re-assessment cycles.";
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "All IS Risks",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value: [
                  overallThreeCycles?.noSelection?.seven ?? 0,
                  overallThreeCycles?.noSelection?.six ?? 0,
                  overallThreeCycles?.noSelection?.five ?? 0,
                  overallThreeCycles?.noSelection?.four ?? 0,
                  overallThreeCycles?.noSelection?.three ?? 0,
                ],
              },
              {
                name: verylow.text,
                value: [
                  overallThreeCycles?.veryLow?.seven ?? 0,
                  overallThreeCycles?.veryLow?.six ?? 0,
                  overallThreeCycles?.veryLow?.five ?? 0,
                  overallThreeCycles?.veryLow?.four ?? 0,
                  overallThreeCycles?.veryLow?.three ?? 0,
                ],
              },
              {
                name: low.text,
                value: [
                  overallThreeCycles?.low?.seven ?? 0,
                  overallThreeCycles?.low?.six ?? 0,
                  overallThreeCycles?.low?.five ?? 0,
                  overallThreeCycles?.low?.four ?? 0,
                  overallThreeCycles?.low?.three ?? 0,
                ],
              },
              {
                name: moderate.text,
                value: [
                  overallThreeCycles?.moderate?.seven ?? 0,
                  overallThreeCycles?.moderate?.six ?? 0,
                  overallThreeCycles?.moderate?.five ?? 0,
                  overallThreeCycles?.moderate?.four ?? 0,
                  overallThreeCycles?.moderate?.three ?? 0,
                ],
              },
              {
                name: high.text,
                value: [
                  overallThreeCycles?.high?.seven ?? 0,
                  overallThreeCycles?.high?.six ?? 0,
                  overallThreeCycles?.high?.five ?? 0,
                  overallThreeCycles?.high?.four ?? 0,
                  overallThreeCycles?.high?.three ?? 0,
                ],
              },
              {
                name: veryHigh.text,
                value: [
                  overallThreeCycles?.veryHigh?.seven ?? 0,
                  overallThreeCycles?.veryHigh?.six ?? 0,
                  overallThreeCycles?.veryHigh?.five ?? 0,
                  overallThreeCycles?.veryHigh?.four ?? 0,
                  overallThreeCycles?.veryHigh?.three ?? 0,
                ],
              },
            ],
          },
        ],
        background: "#f5f5f5",
        titleOfTopText: "Key Message",
        topText: keyMessage,
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories: [
          "7 cycles",
          "6 cycles",
          "5 cycles",
          "4 cycles",
          "3 cycles",
        ],
      };
    });

    const ismsOaRCardReassessment = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "ismsOaRCardReassessment",
      },
    });
    ismsOaRCardReassessment.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const ismsThreeCycles =
        state.dashboard?.ismsThreeCycles ??
        ({} as ScoreForThreeOrMoreAssesments);

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ISMS OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value: [
                  ismsThreeCycles?.noSelection?.seven ?? 0,
                  ismsThreeCycles?.noSelection?.six ?? 0,
                  ismsThreeCycles?.noSelection?.five ?? 0,
                  ismsThreeCycles?.noSelection?.four ?? 0,
                  ismsThreeCycles?.noSelection?.three ?? 0,
                ],
              },
              {
                name: verylow.text,
                value: [
                  ismsThreeCycles?.veryLow?.seven ?? 0,
                  ismsThreeCycles?.veryLow?.six ?? 0,
                  ismsThreeCycles?.veryLow?.five ?? 0,
                  ismsThreeCycles?.veryLow?.four ?? 0,
                  ismsThreeCycles?.veryLow?.three ?? 0,
                ],
              },
              {
                name: low.text,
                value: [
                  ismsThreeCycles?.low?.seven ?? 0,
                  ismsThreeCycles?.low?.six ?? 0,
                  ismsThreeCycles?.low?.five ?? 0,
                  ismsThreeCycles?.low?.four ?? 0,
                  ismsThreeCycles?.low?.three ?? 0,
                ],
              },
              {
                name: moderate.text,
                value: [
                  ismsThreeCycles?.moderate?.seven ?? 0,
                  ismsThreeCycles?.moderate?.six ?? 0,
                  ismsThreeCycles?.moderate?.five ?? 0,
                  ismsThreeCycles?.moderate?.four ?? 0,
                  ismsThreeCycles?.moderate?.three ?? 0,
                ],
              },
              {
                name: high.text,
                value: [
                  ismsThreeCycles?.high?.seven ?? 0,
                  ismsThreeCycles?.high?.six ?? 0,
                  ismsThreeCycles?.high?.five ?? 0,
                  ismsThreeCycles?.high?.four ?? 0,
                  ismsThreeCycles?.high?.three ?? 0,
                ],
              },
              {
                name: veryHigh.text,
                value: [
                  ismsThreeCycles?.veryHigh?.seven ?? 0,
                  ismsThreeCycles?.veryHigh?.six ?? 0,
                  ismsThreeCycles?.veryHigh?.five ?? 0,
                  ismsThreeCycles?.veryHigh?.four ?? 0,
                  ismsThreeCycles?.veryHigh?.three ?? 0,
                ],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories: [
          "7 cycles",
          "6 cycles",
          "5 cycles",
          "4 cycles",
          "3 cycles",
        ],
      };
    });

    const baOaRCardReassessment = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "baOaRCardReassessment",
      },
    });
    baOaRCardReassessment.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const baThreeCycles =
        state.dashboard?.baThreeCycles ?? ({} as ScoreForThreeOrMoreAssesments);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "BA OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value: [
                  baThreeCycles?.noSelection?.seven ?? 0,
                  baThreeCycles?.noSelection?.six ?? 0,
                  baThreeCycles?.noSelection?.five ?? 0,
                  baThreeCycles?.noSelection?.four ?? 0,
                  baThreeCycles?.noSelection?.three ?? 0,
                ],
              },
              {
                name: verylow.text,
                value: [
                  baThreeCycles?.veryLow?.seven ?? 0,
                  baThreeCycles?.veryLow?.six ?? 0,
                  baThreeCycles?.veryLow?.five ?? 0,
                  baThreeCycles?.veryLow?.four ?? 0,
                  baThreeCycles?.veryLow?.three ?? 0,
                ],
              },
              {
                name: low.text,
                value: [
                  baThreeCycles?.low?.seven ?? 0,
                  baThreeCycles?.low?.six ?? 0,
                  baThreeCycles?.low?.five ?? 0,
                  baThreeCycles?.low?.four ?? 0,
                  baThreeCycles?.low?.three ?? 0,
                ],
              },
              {
                name: moderate.text,
                value: [
                  baThreeCycles?.moderate?.seven ?? 0,
                  baThreeCycles?.moderate?.six ?? 0,
                  baThreeCycles?.moderate?.five ?? 0,
                  baThreeCycles?.moderate?.four ?? 0,
                  baThreeCycles?.moderate?.three ?? 0,
                ],
              },
              {
                name: high.text,
                value: [
                  baThreeCycles?.high?.seven ?? 0,
                  baThreeCycles?.high?.six ?? 0,
                  baThreeCycles?.high?.five ?? 0,
                  baThreeCycles?.high?.four ?? 0,
                  baThreeCycles?.high?.three ?? 0,
                ],
              },
              {
                name: veryHigh.text,
                value: [
                  baThreeCycles?.veryHigh?.seven ?? 0,
                  baThreeCycles?.veryHigh?.six ?? 0,
                  baThreeCycles?.veryHigh?.five ?? 0,
                  baThreeCycles?.veryHigh?.four ?? 0,
                  baThreeCycles?.veryHigh?.three ?? 0,
                ],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories: [
          "7 cycles",
          "6 cycles",
          "5 cycles",
          "4 cycles",
          "3 cycles",
        ],
      };
    });

    const itomOaRCardReassessment = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "itomOaRCardReassessment",
      },
    });
    itomOaRCardReassessment.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itomThreeCycles =
        state?.dashboard?.itomThreeCycles ??
        ({} as ScoreForThreeOrMoreAssesments);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ITOM OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value: [
                  itomThreeCycles?.noSelection?.seven ?? 0,
                  itomThreeCycles?.noSelection?.six ?? 0,
                  itomThreeCycles?.noSelection?.five ?? 0,
                  itomThreeCycles?.noSelection?.four ?? 0,
                  itomThreeCycles?.noSelection?.three ?? 0,
                ],
              },
              {
                name: verylow.text,
                value: [
                  itomThreeCycles?.veryLow?.seven ?? 0,
                  itomThreeCycles?.veryLow?.six ?? 0,
                  itomThreeCycles?.veryLow?.five ?? 0,
                  itomThreeCycles?.veryLow?.four ?? 0,
                  itomThreeCycles?.veryLow?.three ?? 0,
                ],
              },
              {
                name: low.text,
                value: [
                  itomThreeCycles?.low?.seven ?? 0,
                  itomThreeCycles?.low?.six ?? 0,
                  itomThreeCycles?.low?.five ?? 0,
                  itomThreeCycles?.low?.four ?? 0,
                  itomThreeCycles?.low?.three ?? 0,
                ],
              },
              {
                name: moderate.text,
                value: [
                  itomThreeCycles?.moderate?.seven ?? 0,
                  itomThreeCycles?.moderate?.six ?? 0,
                  itomThreeCycles?.moderate?.five ?? 0,
                  itomThreeCycles?.moderate?.four ?? 0,
                  itomThreeCycles?.moderate?.three ?? 0,
                ],
              },
              {
                name: high.text,
                value: [
                  itomThreeCycles?.high?.seven ?? 0,
                  itomThreeCycles?.high?.six ?? 0,
                  itomThreeCycles?.high?.five ?? 0,
                  itomThreeCycles?.high?.four ?? 0,
                  itomThreeCycles?.high?.three ?? 0,
                ],
              },
              {
                name: veryHigh.text,
                value: [
                  itomThreeCycles?.veryHigh?.seven ?? 0,
                  itomThreeCycles?.veryHigh?.six ?? 0,
                  itomThreeCycles?.veryHigh?.five ?? 0,
                  itomThreeCycles?.veryHigh?.four ?? 0,
                  itomThreeCycles?.veryHigh?.three ?? 0,
                ],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories: [
          "7 cycles",
          "6 cycles",
          "5 cycles",
          "4 cycles",
          "3 cycles",
        ],
      };
    });

    const serviceOaRCardReassessment = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "serviceOaRCardReassessment",
      },
    });
    serviceOaRCardReassessment.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itServiceThreeCycles =
        state.dashboard?.itServiceThreeCycles ??
        ({} as ScoreForThreeOrMoreAssesments);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "IT-Service OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value: [
                  itServiceThreeCycles?.noSelection?.seven ?? 0,
                  itServiceThreeCycles?.noSelection?.six ?? 0,
                  itServiceThreeCycles?.noSelection?.five ?? 0,
                  itServiceThreeCycles?.noSelection?.four ?? 0,
                  itServiceThreeCycles?.noSelection?.three ?? 0,
                ],
              },
              {
                name: verylow.text,
                value: [
                  itServiceThreeCycles?.veryLow?.seven ?? 0,
                  itServiceThreeCycles?.veryLow?.six ?? 0,
                  itServiceThreeCycles?.veryLow?.five ?? 0,
                  itServiceThreeCycles?.veryLow?.four ?? 0,
                  itServiceThreeCycles?.veryLow?.three ?? 0,
                ],
              },
              {
                name: low.text,
                value: [
                  itServiceThreeCycles?.low?.seven ?? 0,
                  itServiceThreeCycles?.low?.six ?? 0,
                  itServiceThreeCycles?.low?.five ?? 0,
                  itServiceThreeCycles?.low?.four ?? 0,
                  itServiceThreeCycles?.low?.three ?? 0,
                ],
              },
              {
                name: moderate.text,
                value: [
                  itServiceThreeCycles?.moderate?.seven ?? 0,
                  itServiceThreeCycles?.moderate?.six ?? 0,
                  itServiceThreeCycles?.moderate?.five ?? 0,
                  itServiceThreeCycles?.moderate?.four ?? 0,
                  itServiceThreeCycles?.moderate?.three ?? 0,
                ],
              },
              {
                name: high.text,
                value: [
                  itServiceThreeCycles?.high?.seven ?? 0,
                  itServiceThreeCycles?.high?.six ?? 0,
                  itServiceThreeCycles?.high?.five ?? 0,
                  itServiceThreeCycles?.high?.four ?? 0,
                  itServiceThreeCycles?.high?.three ?? 0,
                ],
              },
              {
                name: veryHigh.text,
                value: [
                  itServiceThreeCycles?.veryHigh?.seven ?? 0,
                  itServiceThreeCycles?.veryHigh?.six ?? 0,
                  itServiceThreeCycles?.veryHigh?.five ?? 0,
                  itServiceThreeCycles?.veryHigh?.four ?? 0,
                  itServiceThreeCycles?.veryHigh?.three ?? 0,
                ],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories: [
          "7 cycles",
          "6 cycles",
          "5 cycles",
          "4 cycles",
          "3 cycles",
        ],
      };
    });

    const legendCardReassessment = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legendCardReassessment",
      },
    });

    legendCardReassessment.mapState(() => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });

    const risksFirstPublishedCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "risksFirstPublishedCardTitle",
        xs: 12,
      },
    });
    risksFirstPublishedCardTitle.mapState(() => ({
      cardProps: {
        sx: {
          "@media print": {
            pageBreakBefore: "always",
          },
        },
      },
      title: "IS Risks by First Published date and Risk Score",
    }));

    const allIsRisksFirstPublishedCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "allIsRisksFirstPublishedCard",
        marginX: 2,
        marginY: 1,
      },
    });
    allIsRisksFirstPublishedCard.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;

      const overallFirstPublished =
        state.dashboard?.overallFirstPublished ??
        ({} as ScoreForAdditionalAttentionPoints);

      const risksOlderThanTwoYears = {
        noSelection: overallFirstPublished?.noSelection?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
        veryLow: overallFirstPublished?.veryLow?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
        low: overallFirstPublished?.low?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
        moderate: overallFirstPublished?.moderate?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
        high: overallFirstPublished?.high?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
        veryHigh: overallFirstPublished?.veryHigh?.filter((a) =>
          isOlderThanTwoYears(a.key)
        ),
      };

      const numberOfRisksPublishedMoreThan2Years =
        risksOlderThanTwoYears?.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksOlderThanTwoYears?.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksOlderThanTwoYears?.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksOlderThanTwoYears?.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksOlderThanTwoYears?.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksOlderThanTwoYears?.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);

      const firstSectionKeyMessage =
        numberOfRisksPublishedMoreThan2Years > 0
          ? `${numberOfRisksPublishedMoreThan2Years} out of ${state?.dashboard?.totalNumberOfRisks ?? 0} (${getPercentage(state?.dashboard?.totalNumberOfRisks ?? 0, numberOfRisksPublishedMoreThan2Years)}%) IS risks are open for more than 2 years. These risks should be considered for closure.\n\n Out of these risks there are ${risksOlderThanTwoYears?.veryHigh?.map((r) => r.count).reduce((acc, curr) => acc + curr, 0)} Very High risks, ${risksOlderThanTwoYears?.high?.map((r) => r.count).reduce((acc, curr) => acc + curr, 0)} High risks, ${risksOlderThanTwoYears?.moderate?.map((r) => r.count).reduce((acc, curr) => acc + curr, 0)} Moderat risks, ${risksOlderThanTwoYears?.low?.map((r) => r.count).reduce((acc, curr) => acc + curr, 0)} Low risks, ${risksOlderThanTwoYears?.veryLow?.map((r) => r.count).reduce((acc, curr) => acc + curr, 0)} Very Low risks.`
          : "There are no risks open for more than 2 years";

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "All IS Risks",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overallFirstPublished?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  overallFirstPublished?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  overallFirstPublished?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overallFirstPublished?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  overallFirstPublished?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  overallFirstPublished?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        background: "#f5f5f5",
        topText: firstSectionKeyMessage,
        titleOfTopText: "Key Message",
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overallFirstPublished?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const ismsOaRCardFirstPublished = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "ismsOaRCardFirstPublished",
      },
    });
    ismsOaRCardFirstPublished.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const ismsFirstPublished =
        state.dashboard?.ismsFirstPublished ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ISMS OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  ismsFirstPublished?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  ismsFirstPublished?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  ismsFirstPublished?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  ismsFirstPublished?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value: ismsFirstPublished?.high?.map((entry) => entry.count),
              },
              {
                name: veryHigh.text,
                value:
                  ismsFirstPublished?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          ismsFirstPublished?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const baOaRCardFirstPublished = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "baOaRCardFirstPublished",
      },
    });
    baOaRCardFirstPublished.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const baFirstPublished =
        state.dashboard?.baFirstPublished ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "BA OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  baFirstPublished?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  baFirstPublished?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value: baFirstPublished?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  baFirstPublished?.moderate?.map((entry) => entry.count) ?? [],
              },
              {
                name: high.text,
                value:
                  baFirstPublished?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  baFirstPublished?.veryHigh?.map((entry) => entry.count) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          baFirstPublished?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const itomOaRCardFirstPublished = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "itomOaRCardFirstPublished",
      },
    });
    itomOaRCardFirstPublished.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itomFirstPublished =
        state.dashboard?.itomFirstPublished ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ITOM OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itomFirstPublished?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itomFirstPublished?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  itomFirstPublished?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  itomFirstPublished?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  itomFirstPublished?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  itomFirstPublished?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itomFirstPublished?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const serviceOaRCardFirstPublished = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "serviceOaRCardFirstPublished",
      },
    });
    serviceOaRCardFirstPublished.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itServiceFirstPublished =
        state.dashboard?.itServiceFirstPublished ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "IT-Service OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itServiceFirstPublished?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itServiceFirstPublished?.veryLow?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: low.text,
                value:
                  itServiceFirstPublished?.low?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: moderate.text,
                value:
                  itServiceFirstPublished?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  itServiceFirstPublished?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  itServiceFirstPublished?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itServiceFirstPublished?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const legendCardFirstPublished = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legendCardFirstPublished",
      },
    });

    legendCardFirstPublished.mapState(() => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });

    const risksEstimatedCompletionCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "risksEstimatedCompletionCardTitle",
        xs: 12,
      },
    });
    risksEstimatedCompletionCardTitle.mapState(() => ({
      cardProps: {
        sx: {
          "@media print": {
            pageBreakBefore: "always",
          },
        },
      },
      title:
        "IS Risks by Estimated Completion Date of Mitigation Plans by Risk Score ",
    }));

    const allIsRisksEstimatedCompletionCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "allIsRisksEstimatedCompletionCard",
        marginX: 2,
        marginY: 1,
      },
    });
    allIsRisksEstimatedCompletionCard.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overallEstCompletionDate =
        state.dashboard?.overallEstCompletionDate ??
        ({} as ScoreForAdditionalAttentionPoints);

      const risksDueThisQuarter = {
        noSelection: overallEstCompletionDate?.noSelection?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        veryLow: overallEstCompletionDate?.veryLow?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        low: overallEstCompletionDate?.low?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        moderate: overallEstCompletionDate?.moderate?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        high: overallEstCompletionDate?.high?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        veryHigh: overallEstCompletionDate?.veryHigh?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
      };

      const risksDueNextQuarter = {
        noSelection: overallEstCompletionDate?.noSelection?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        veryLow: overallEstCompletionDate?.veryLow?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        low: overallEstCompletionDate?.low?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        moderate: overallEstCompletionDate?.moderate?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        high: overallEstCompletionDate?.high?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        veryHigh: overallEstCompletionDate?.veryHigh?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
      };

      const risksDueBeyondOneYear = {
        noSelection: overallEstCompletionDate?.noSelection?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
        veryLow: overallEstCompletionDate?.veryLow?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
        low: overallEstCompletionDate?.low?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
        moderate: overallEstCompletionDate?.moderate?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
        high: overallEstCompletionDate?.high?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
        veryHigh: overallEstCompletionDate?.veryHigh?.filter((a) =>
          isBeyondOneYear(a.key)
        ),
      };

      const numberOfRisksDueThisQuarter =
        risksDueThisQuarter?.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);

      const numberOfRisksDueNextQuarter =
        risksDueNextQuarter?.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);
      const numberOfRisksDueBeyondOneYear =
        risksDueBeyondOneYear.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueBeyondOneYear.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueBeyondOneYear.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueBeyondOneYear.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueBeyondOneYear.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueBeyondOneYear.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);

      const firstSectionKeyMessage =
        "This section only refers to risks in M&R with a response to mitigate.";

      const secondSectionKeyMessage = `There are ${numberOfRisksDueThisQuarter} IS risks due in ${getCurrentQuarterYear()}. These risks require immediate attention.`;
      const thirdSectionKeyMessage =
        risksDueNextQuarter?.veryHigh
          ?.map((risk) => risk.count)
          .reduce((curr, acca) => curr + acca, 0) +
          risksDueNextQuarter?.high
            ?.map((risk) => risk.count)
            .reduce((curr, acca) => curr + acca, 0) >
        0
          ? `Out of these ${risksDueNextQuarter?.veryHigh?.map((risk) => risk.count).reduce((curr, acca) => curr + acca, 0)} are Very High risks, and ${risksDueNextQuarter?.high?.map((risk) => risk.count).reduce((curr, acca) => curr + acca, 0)} are High risks`
          : "";

      const fourthSectionKeyMessage =
        numberOfRisksDueNextQuarter > 0
          ? `There are ${numberOfRisksDueNextQuarter} IS risks due in ${getNextQuarterYear()}.These risks need to be monitored and closed on a timely manner to avoid overdue mitigation plans.`
          : "";

      const fifthSectionKeyMessage =
        numberOfRisksDueBeyondOneYear > 0
          ? `${numberOfRisksDueBeyondOneYear} risks have mitigation plans beyond 1 year and these risks need to be reviewed. Review risks with mitigation plan timeline beyond 1 year.`
          : "";

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "All IS Risks",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overallEstCompletionDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  overallEstCompletionDate?.veryLow?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: low.text,
                value:
                  overallEstCompletionDate?.low?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: moderate.text,
                value:
                  overallEstCompletionDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  overallEstCompletionDate?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  overallEstCompletionDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        background: "#f5f5f5",
        topText: formatListWithBullets([
          firstSectionKeyMessage,
          secondSectionKeyMessage,
          thirdSectionKeyMessage,
          fourthSectionKeyMessage,
          fifthSectionKeyMessage,
        ]),
        titleOfTopText: "Key Message",
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overallEstCompletionDate?.noSelection?.map((val) => val.key) ?? [],
      };
    });

    const ismsOaRCardEstimatedCompletion = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "ismsOaRCardEstimatedCompletion",
      },
    });
    ismsOaRCardEstimatedCompletion.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const ismsEstCompletionDate =
        state.dashboard?.ismsEstCompletionDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ISMS OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  ismsEstCompletionDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  ismsEstCompletionDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  ismsEstCompletionDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  ismsEstCompletionDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  ismsEstCompletionDate?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  ismsEstCompletionDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          ismsEstCompletionDate?.noSelection?.map((val) => val.key) ?? [],
      };
    });

    const baOaRCardEstimatedCompletion = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "baOaRCardEstimatedCompletion",
      },
    });
    baOaRCardEstimatedCompletion.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const baEstCompletionDate =
        state.dashboard?.baEstCompletionDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "BA OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  baEstCompletionDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  baEstCompletionDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  baEstCompletionDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  baEstCompletionDate?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  baEstCompletionDate?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  baEstCompletionDate?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          baEstCompletionDate?.noSelection?.map((val) => val.key) ?? [],
      };
    });

    const itomOaRCardEstimatedCompletion = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "itomOaRCardEstimatedCompletion",
      },
    });
    itomOaRCardEstimatedCompletion.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itomEstCompletionDate =
        state.dashboard?.itomEstCompletionDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ITOM OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itomEstCompletionDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itomEstCompletionDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  itomEstCompletionDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  itomEstCompletionDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  itomEstCompletionDate?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  itomEstCompletionDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itomEstCompletionDate?.noSelection?.map((val) => val.key) ?? [],
      };
    });

    const serviceOaRCardEstimatedCompletion = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "serviceOaRCardEstimatedCompletion",
      },
    });
    serviceOaRCardEstimatedCompletion.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itServiceEstCompletionDate =
        state.dashboard?.itServiceEstCompletionDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "IT-Service OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itServiceEstCompletionDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itServiceEstCompletionDate?.veryLow?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: low.text,
                value:
                  itServiceEstCompletionDate?.low?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: moderate.text,
                value:
                  itServiceEstCompletionDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  itServiceEstCompletionDate?.high?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  itServiceEstCompletionDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itServiceEstCompletionDate?.noSelection?.map((val) => val.key) ?? [],
      };
    });

    const legendCardEstimatedCompletion = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legendCardEstimatedCompletion",
      },
    });

    legendCardEstimatedCompletion.mapState(() => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });

    const risksAcceptedResponseCardTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "risksAcceptedResponseCardTitle",
        xs: 12,
      },
    });
    risksAcceptedResponseCardTitle.mapState(() => ({
      cardProps: {
        sx: {
          "@media print": {
            pageBreakBefore: "always",
          },
        },
      },
      title:
        "IS Risks with response decision “Accept” by Expiration Date and Risk Score",
    }));

    const allIsRisksAcceptedResponseCard = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "allIsRisksAcceptedResponseCard",
        marginX: 2,
        marginY: 1,
      },
    });
    allIsRisksAcceptedResponseCard.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const overallExpirationDate =
        state.dashboard?.overallExpirationDate ??
        ({} as ScoreForAdditionalAttentionPoints);

      const risksDueThisQuarter = {
        noSelection: overallExpirationDate?.noSelection?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        veryLow: overallExpirationDate?.veryLow?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        low: overallExpirationDate?.low?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        moderate: overallExpirationDate?.moderate?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        high: overallExpirationDate?.high?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
        veryHigh: overallExpirationDate?.veryHigh?.filter(
          (a) => a.key === getCurrentQuarterYear()
        ),
      };

      const risksDueNextQuarter = {
        noSelection: overallExpirationDate?.noSelection?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        veryLow: overallExpirationDate?.veryLow?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        low: overallExpirationDate?.low?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        moderate: overallExpirationDate?.moderate?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        high: overallExpirationDate?.high?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
        veryHigh: overallExpirationDate?.veryHigh?.filter(
          (a) => a.key === getNextQuarterYear()
        ),
      };

      const numberOfRisksDueThisQuarter =
        risksDueThisQuarter?.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueThisQuarter?.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);
      const numberOfRisksDueNextQuarter =
        risksDueNextQuarter?.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        risksDueNextQuarter?.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);
      const totalNumberOfRisks =
        overallExpirationDate.veryHigh
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        overallExpirationDate.high
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        overallExpirationDate.moderate
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        overallExpirationDate.low
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        overallExpirationDate.veryLow
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0) +
        overallExpirationDate.noSelection
          ?.map((r) => r.count)
          .reduce((acc, curr) => acc + curr, 0);

      const firstSectionKeyMessage =
        "This section only refers to risks in M&R with a response to accepted.";

      const secondSectionKeyMessage = `Accepted risks without mitigation plans need to be re-assessed after the expiration. The default expiration date for Very High risks is 3 months; for High risks, 6 months; for Moderate, Low and Very Low Risks, 1 year.`;

      const thirdSectionKeyMessage =
        numberOfRisksDueThisQuarter > 0
          ? `In ${getCurrentQuarterYear()}, ${numberOfRisksDueThisQuarter} accepted IS risks without mitigation plans are set to expire. These risks require immediate attention.`
          : "";

      const fourthSectionKeyMessage =
        risksDueNextQuarter?.veryHigh
          ?.map((risk) => risk.count)
          .reduce((curr, acca) => curr + acca, 0) +
          risksDueNextQuarter?.high
            ?.map((risk) => risk.count)
            .reduce((curr, acca) => curr + acca, 0) >
        0
          ? `Out of these ${risksDueNextQuarter?.veryHigh?.map((risk) => risk.count).reduce((curr, acca) => curr + acca, 0)} are Very High risks, and ${risksDueNextQuarter?.high?.map((risk) => risk.count).reduce((curr, acca) => curr + acca, 0)} are High risks`
          : "";

      const fifthSectionKeyMessage =
        numberOfRisksDueNextQuarter > 0
          ? ` In ${getNextQuarterYear()}, ${numberOfRisksDueNextQuarter} accepted IS risks without mitigation plans are set to expire. These risks need to be monitored and closed on a timely manner.`
          : "";

      const sixthSectionKeyMessage =
        totalNumberOfRisks === 0
          ? "There are no IS risks with response decision “Accept” in M&R."
          : "";

      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "All IS Risks",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  overallExpirationDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  overallExpirationDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  overallExpirationDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  overallExpirationDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  overallExpirationDate?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  overallExpirationDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        background: "#f5f5f5",
        topText: formatListWithBullets([
          firstSectionKeyMessage,
          secondSectionKeyMessage,
          thirdSectionKeyMessage,
          fourthSectionKeyMessage,
          fifthSectionKeyMessage,
          sixthSectionKeyMessage,
        ]),

        titleOfTopText: "Key Message",
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          overallExpirationDate?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const ismsOaRCardAcceptedResponse = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "ismsOaRCardAcceptedResponse",
      },
    });
    ismsOaRCardAcceptedResponse.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const ismsExpirationDate =
        state.dashboard?.ismsExpirationDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ISMS OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  ismsExpirationDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  ismsExpirationDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  ismsExpirationDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  ismsExpirationDate?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  ismsExpirationDate?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  ismsExpirationDate?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          ismsExpirationDate?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const baOaRCardAcceptedResponse = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "baOaRCardAcceptedResponse",
      },
    });
    baOaRCardAcceptedResponse.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const baExpirationDate =
        state.dashboard?.baExpirationDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "BA OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  baExpirationDate?.noSelection?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: verylow.text,
                value:
                  baExpirationDate?.veryLow?.map((entry) => entry.count) ?? [],
              },
              {
                name: low.text,
                value: baExpirationDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  baExpirationDate?.moderate?.map((entry) => entry.count) ?? [],
              },
              {
                name: high.text,
                value:
                  baExpirationDate?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  baExpirationDate?.veryHigh?.map((entry) => entry.count) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          baExpirationDate?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const itomOaRCardAcceptedResponse = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "itomOaRCardAcceptedResponse",
      },
    });
    itomOaRCardAcceptedResponse.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itomExpirationDate =
        state.dashboard?.itomExpirationDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "ITOM OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itomExpirationDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itomExpirationDate?.veryLow?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: low.text,
                value:
                  itomExpirationDate?.low?.map((entry) => entry.count) ?? [],
              },
              {
                name: moderate.text,
                value:
                  itomExpirationDate?.moderate?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: high.text,
                value:
                  itomExpirationDate?.high?.map((entry) => entry.count) ?? [],
              },
              {
                name: veryHigh.text,
                value:
                  itomExpirationDate?.veryHigh?.map((entry) => entry.count) ??
                  [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itomExpirationDate?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const serviceOaRCardAcceptedResponse = view.addComponent({
      component: StackedBarChart,
      layoutProps: {
        id: "serviceOaRCardAcceptedResponse",
      },
    });
    serviceOaRCardAcceptedResponse.mapState((state) => {
      const { noSelection, veryHigh, high, moderate, low, verylow } =
        RISK_TYPE_DETAILS;
      const itServiceExpirationDate =
        state.dashboard?.itServiceExpirationDate ??
        ({} as ScoreForAdditionalAttentionPoints);
      return {
        cardProps: {
          sx: {
            flex: 1,
          },
        },
        title: "IT-Service OaR",
        groupedSeries: [
          {
            series: [
              {
                name: noSelection.text,
                value:
                  itServiceExpirationDate?.noSelection?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: verylow.text,
                value:
                  itServiceExpirationDate?.veryLow?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: low.text,
                value:
                  itServiceExpirationDate?.low?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: moderate.text,
                value:
                  itServiceExpirationDate?.moderate?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
              {
                name: high.text,
                value:
                  itServiceExpirationDate?.high?.map((entry) => entry.count) ??
                  [],
              },
              {
                name: veryHigh.text,
                value:
                  itServiceExpirationDate?.veryHigh?.map(
                    (entry) => entry.count
                  ) ?? [],
              },
            ],
          },
        ],
        colors: [
          noSelection.color,
          verylow.color,
          low.color,
          moderate.color,
          high.color,
          veryHigh.color,
        ],
        categories:
          itServiceExpirationDate?.noSelection?.map((entry) => entry.key) ?? [],
      };
    });

    const legendCardAcceptedResponse = view.addComponent({
      component: SeparateHorizontalLegend,
      layoutProps: {
        id: "legendCardAcceptedResponse",
      },
    });

    legendCardAcceptedResponse.mapState(() => {
      const { noSelection, veryHigh, verylow, low, high, moderate } =
        RISK_TYPE_DETAILS;
      return {
        title: "Risk Score",
        entries: [
          { title: noSelection.text, color: noSelection.color },
          { title: verylow.text, color: verylow.color },
          { title: low.text, color: low.color },
          { title: moderate.text, color: moderate.color },
          { title: high.text, color: high.color },
          { title: veryHigh.text, color: veryHigh.color },
        ],
      };
    });
  }

  private buildAppendixSection(view: View<RrAppState, CompoundLayoutProps>) {
    const appendixHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "appendixHeader",
      },
    });

    appendixHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[8],
      id: DASHBOARD_PAGE_SECTIONS[8],
      sx: { scrollMarginTop: "80px" },
    }));

    const appendixApprovedQuestionnairesDetails = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "appendixApprovedQuestionnairesDetails",
      },
    });

    appendixApprovedQuestionnairesDetails.mapState(() => ({
      title: "Questionnaire",
    }));

    const appendixApprovedQuestionnairesSubheader = view.addComponent({
      component: SimpleTextCardWithTitle,
      layoutProps: { id: "appendixApprovedQuestionnairesSubheader" },
    });
    appendixApprovedQuestionnairesSubheader.mapState(() => ({
      text: "Criticality reflects the inherent risk of ISMS as OaR in the OE (AFIRM v3.0) and takes values Very Low, Low, Medium, High and Very High.",
      cardProps: { sx: { p: 0, m: 0 } },
    }));

    const appendixApprovedQuestionnairesTable = view.addComponent({
      component: TableWithChipHeaders,
      layoutProps: { id: "appendixApprovedQuestionnairesTable" },
    });

    appendixApprovedQuestionnairesTable.mapState((state) => {
      return {
        headers: [
          { title: "" },
          ...RISK_SEVERITIES.reverse().map((risk) => ({
            title: risk.text,
            cellProps: {
              sx: {
                background: risk.color,
                color: "white",
                borderRadius: "12px",
              },
            },
          })),
        ],
        entries: [
          [
            {
              title: "# of risks per OE assigned to incorrect owner ",
              cellProps: { sx: { fontWeight: 500 } },
            },
            { title: state.dashboard?.veryHighIncorrect?.toString() ?? "0" },
            { title: state.dashboard?.highIncorrect?.toString() ?? "0" },
            { title: state.dashboard?.moderateIncorrect?.toString() ?? "0" },
            { title: state.dashboard?.lowIncorrect?.toString() ?? "0" },
            { title: state.dashboard?.veryLowIncorrect?.toString() ?? "0" },
            { title: state.dashboard?.noSelectionIncorrect?.toString() ?? "0" },
          ],
          [
            {
              title: "# of High and Very High Risks per OE",
              cellProps: { sx: { fontWeight: 500 } },
            },
            {
              title:
                state.dashboard?.veryHighHighAndVeryHighRisks?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.highHighAndVeryHighRisks?.toString() ?? "0",
            },
            {
              title:
                state.dashboard?.moderateHighAndVeryHighRisks?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.lowHighAndVeryHighRisks?.toString() ?? "0",
            },
            {
              title:
                state.dashboard?.veryLowHighAndVeryHighRisks?.toString() ?? "0",
            },
            {
              title:
                state.dashboard?.noSelectionHighAndVeryHighRisks?.toString() ??
                "0",
            },
          ],
          [
            {
              title: "# of Risks with delayed process per OE ",
              cellProps: { sx: { fontWeight: 500 } },
            },
            { title: state.dashboard?.veryHighDelayed?.toString() ?? "0" },
            { title: state.dashboard?.highDelayed?.toString() ?? "0" },
            { title: state.dashboard?.moderateDelayed?.toString() ?? "0" },
            { title: state.dashboard?.lowDelayed?.toString() ?? "0" },
            { title: state.dashboard?.veryLowDelayed?.toString() ?? "0" },
            { title: state.dashboard?.noSelectionDelayed?.toString() ?? "0" },
          ],
          [
            {
              title:
                "OEs with Overdue Mitigation Plans for High and Very High risks",
              cellProps: { sx: { fontWeight: 500 } },
            },
            {
              title: state.dashboard?.veryHighOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
            {
              title: state.dashboard?.highOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
            {
              title: state.dashboard?.moderateOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
            {
              title: state.dashboard?.lowOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
            {
              title: state.dashboard?.veryLowOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
            {
              title: state.dashboard?.noSelectionOeName?.join("\n") ?? "-",
              cellProps: {
                sx: {
                  whiteSpace: "pre-wrap",
                  overflow: "auto",
                  height: 120,
                  minWidth: 150,
                  maxWidth: 200,
                },
              },
            },
          ],
          [
            {
              title: "# of accepted Risks without mitigation plans per OE",
              cellProps: { sx: { fontWeight: 500 } },
            },
            {
              title: state.dashboard?.veryHighAcceptedRisks?.toString() ?? "0",
            },
            { title: state.dashboard?.highAcceptedRisks?.toString() ?? "0" },
            {
              title: state.dashboard?.moderateAcceptedRisks?.toString() ?? "0",
            },
            { title: state.dashboard?.lowAcceptedRisks?.toString() ?? "0" },
            { title: state.dashboard?.veryLowAcceptedRisks?.toString() ?? "0" },
            {
              title:
                state.dashboard?.noSelectionAcceptedRisks?.toString() ?? "0",
            },
          ],
          [
            {
              title: "# of Accepted High and Very High Risks per OE ",
              cellProps: { sx: { fontWeight: 500 } },
            },
            {
              title:
                state.dashboard?.veryHighAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.highAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.moderateAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.lowAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.veryLowAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
            {
              title:
                state.dashboard?.noSelectionAcceptedRisksHighAndVeryHigh?.toString() ??
                "0",
            },
          ],
        ],
        cardProps: {
          key: "appendixApprovedQuestionnairesTable",
        },
      };
    });
  }

  private buildAbbrevationsSection(
    view: View<RrAppState, CompoundLayoutProps>
  ) {
    const abbreviationsHeader = view.addComponent({
      component: SectionHeader,
      layoutProps: {
        xs: 12,
        id: "abbreviationsHeader",
      },
    });

    abbreviationsHeader.mapState(() => ({
      title: DASHBOARD_PAGE_SECTIONS[9],
      id: DASHBOARD_PAGE_SECTIONS[9],
      sx: { scrollMarginTop: "80px" },
    }));
    const abbreviationsTitle = view.addComponent({
      component: ComponentTitleCard,
      layoutProps: {
        id: "abbreviationsTitle",
      },
    });

    abbreviationsTitle.mapState(() => ({
      title: "Abbreviations Used in this Report",
    }));

    const tableDefinition: TableDefinition = [
      { component: TextCardCell },
      { component: TextCardCell },
    ] as const;
    const table = new Table(tableDefinition, "report");
    const abbreviationsTable = view.addComponent<
      DeriveTableProps<typeof table>,
      unknown,
      unknown
    >({
      component: table,
      layoutProps: {
        id: "abbreviationsTable",
      },
    });
    abbreviationsTable.mapState(() => {
      return {
        data: ABBREVIATIONS.map(
          (e): DeriveTableProps<typeof table>["data"][number] => {
            return {
              rowProps: {},
              columnProps: [
                {
                  body: e.term,

                  bodyProps: {
                    sx: {
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      minWidth: 150,
                      maxWidth: 200,
                      fontWeight: 400,
                      fontSize: "16px",
                    },
                  },
                },

                {
                  body: e.description,
                  bodyProps: {
                    sx: {
                      display: "-webkit-box",
                      overflow: "hidden",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      minWidth: 150,
                      fontWeight: 400,
                      fontSize: "16px",
                    },
                  },
                },
              ],
            };
          }
        ),
        tableHeaderEntries: ["Terms", "Description"],
      };
    });
  }
}

function transform(inputArray: OeProject[]): {
  division: string;
  oes: Array<{
    oeName: string;
    score: number;
    chipColor: string;
    textColor: string;
  }>;
}[] {
  const map = new Map<
    string,
    Array<{
      oeName: string;
      score: number;
      chipColor: string;
      textColor: string;
    }>
  >();

  // Loop through each item in the input array
  inputArray.forEach((item) => {
    // Check if the division is already a key in the map
    if (!map.has(item.division)) {
      // If not, initialize it with an array containing the first object
      map.set(item.division, [
        {
          oeName: item.oeName,
          score: item.score,
          chipColor: "",
          textColor: "",
        },
      ]);
    } else {
      // If yes, push the new object to the existing array
      map.get(item.division)?.push({
        oeName: item.oeName,
        score: item.score,
        chipColor: "",
        textColor: "",
      });
    }
  });

  // Transform the map into the desired array format
  const result: {
    division: string;
    oes: Array<{
      oeName: string;
      score: number;
      chipColor: string;
      textColor: string;
    }>;
  }[] = [];
  map.forEach((value, key) => {
    result.push({ division: key, oes: value });
  });

  return result;
}
