import {
  DefaultOverlay,
  DefaultOverlayEventNames,
  type DefaultOverlayEventPayload,
  type DefaultOverlayProps,
} from "@pimo/pimo-components";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import { FileDownloadOutlined, Logout } from "@mui/icons-material";
import { Box, Button } from "@mui/material";

export type RROverlayProps = DefaultOverlayProps & { reportingDate?: string };

export type RROverlayEventNames =
  | DefaultOverlayEventNames
  | "overlay:download-report";

type RROverlayEventPayload =
  | { reportingDate: string }
  | DefaultOverlayEventPayload;

export const RROverlay: PimoReactComponent<
  RROverlayProps,
  RROverlayEventNames,
  RROverlayEventPayload
> = ({ fireEvent, ...props }) => {
  const headerProps: DefaultOverlayProps["header"] = {
    ...props.header,
    children: (
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          onClick={() =>
            (location.href = "mailto:isrm@allianz.com?subject=RISK REPORTING:")
          }
          sx={{
            background: "#E5A537",
            color: "white",
            px: 2,
            textTransform: "none",
            ":hover": { background: "#E5A537", opacity: 0.75 },
          }}
        >
          Need Help?
        </Button>
      </Box>
    ),
    entries: [
      {
        icon: FileDownloadOutlined,
        onClick: () => fireEvent?.("overlay:download-report"),
        text: "Download PDF Report",
      },
      {
        icon: Logout,
        onClick: () => fireEvent?.("overlay:logout"),
        text: "Logout",
      },
    ],
  };
  return (
    <DefaultOverlay {...props} fireEvent={fireEvent} header={headerProps} />
  );
};
